import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGasPump, faShop, faCalendarDays, faHouse, faChartColumn, faRectangleList } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';


const MainNav = ({ username, onLogoutClicked }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto d-none d-lg-flex">
                            <Nav.Link as={Link} to='/dashboard/overview' className='side-link'>
                                <FontAwesomeIcon icon={faHouse} /> Επισκόπηση
                            </Nav.Link>
                            <Nav.Link as={Link} to='/dashboard/coffeestores' className='side-link'>
                                <FontAwesomeIcon icon={faShop} /> Χαρτογράφηση
                            </Nav.Link>
                            <Nav.Link as={Link} to='#' className='side-link isDisabled'>
                                <FontAwesomeIcon icon={faShop} /> Πελάτες
                            </Nav.Link>
                            <Nav.Link as={Link} to='/dashboard/fuels' className='side-link'>
                                <FontAwesomeIcon icon={faGasPump} /> Καύσιμα
                            </Nav.Link>
                            <Nav.Link as={Link} to='/dashboard/checklists' className='side-link isDisabled' disabled>
                                <FontAwesomeIcon icon={faRectangleList} /> Checklists
                            </Nav.Link>
                            <Nav.Link as={Link} to='/dashboard/projects' className='side-link'>
                                <FontAwesomeIcon icon={faCalendarDays} /> Projects
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Offcanvas show={show} onHide={handleClose} id="offcanvasNavbar">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{username}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="me-auto flex-column fs-4 h-100" onClick={handleClose}>
                        <Nav.Link as={Link} to='/dashboard/overview' className='side-link'>
                            <FontAwesomeIcon icon={faHouse} /> Επισκόπηση
                        </Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/coffeestores' className='side-link'>
                            <FontAwesomeIcon icon={faShop} /> Χαρτογράφηση
                        </Nav.Link>
                        <Nav.Link as={Link} to='#' className='side-link isDisabled'>
                            <FontAwesomeIcon icon={faShop} /> Πελάτες
                        </Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/fuels' className='side-link'>
                            <FontAwesomeIcon icon={faGasPump} /> Καύσιμα
                        </Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/checklists' className='side-link isDisabled' disabled>
                            <FontAwesomeIcon icon={faRectangleList} /> Checklists
                        </Nav.Link>
                        <Nav.Link as={Link} to='/dashboard/projects' className='side-link'>
                            <FontAwesomeIcon icon={faCalendarDays} /> Projects
                        </Nav.Link>

                        <div className='mt-auto'>
                            <Nav.Link as={Link} to="/dashboard/user/edit" onClick={handleClose}>Αλλαγή Κωδικού</Nav.Link>
                            <Nav.Link as={Link} to="#" onClick={() => { handleClose(); onLogoutClicked(); }}>Αποσύνδεση</Nav.Link>
                        </div>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default MainNav