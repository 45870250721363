import React from 'react'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useGetCoffeeStoresQuery } from './storesApiSlice'
import EditStoreForm from './EditStoreForm'

const EditStore = () => {

    const { id } = useParams()
    const { username } = useAuth()

    const { store } = useGetCoffeeStoresQuery('storesList', {
        selectFromResult: ({ data }) => ({
            store: data?.entities[id]
        })
    })

    if (store && (store.userID?.username === username || username === 'g.varsamis')) {
        return <EditStoreForm store={store}/>
    } else {
        return (<div>Δεν έχετε δικαίωμα επεξεργασίας αυτής της καταχώρησης.</div>)
    }
}

export default EditStore