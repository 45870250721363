import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useGetCoffeeStoresQuery, useUpdateStoreMutation } from './storesApiSlice';
import '../../assets/css/card.css'
import { storeTypesList } from '../../config/storeTypes'
import AddToSchedule from '../userSchedule.js/AddToSchedule';
import useAuth from '../../hooks/useAuth';

const StoreCard = ({ id, show, handleCloseView, selectedStore }) => {

    const { id: u_id, roles } = useAuth()

    const store = selectedStore

    const [prefecture, setPrefecture] = useState(store.prefecture)
    const [city, setCity] = useState(store.city)
    const [storeTitle, setStoreTitle] = useState(store.storeTitle)
    const [entryType, setEntryType] = useState(store.entryType)
    const [storeType, setStoreType] = useState(store.storeType)
    const [coffee, setCoffee] = useState(store.coffee)
    const [consumption, setConsumption] = useState(store.consumption)
    const [storeOwner, setStoreOwner] = useState(store.contactInformation.storeOwner)
    const [contactPhone, setContactPhone] = useState(store.contactInformation.contactPhone)
    const [contactEmail, setContactEmail] = useState(store.contactInformation.contactEmail)

    const onPrefectureChange = e => setPrefecture(e.target.value)
    const onCityChange = e => setCity(e.target.value)
    const onStoreTitleChange = e => setStoreTitle(e.target.value)
    const onEntryTypeChange = e => setEntryType(e.target.value)
    const onStoreTypeChange = e => setStoreType(e.target.value)
    const onCoffeeChange = e => setCoffee(e.target.value)
    const onConsumptionChange = e => setConsumption(e.target.value)
    const onStoreOwnerChange = e => setStoreOwner(e.target.value)
    const onContactPhoneChange = e => setContactPhone(e.target.value)
    const onContactEmailChange = e => setContactEmail(e.target.value)

    const [storeName, setStoreName] = useState(store.interest?.storeName)
    const [VAT, setVAT] = useState(store.interest?.VAT)
    const [contactType, setContactType] = useState(store.interest?.contactType)
    const [products, setProducts] = useState(store.interest?.products)

    const onStoreNameChange = e => setStoreName(e.target.value)
    const onVatChange = e => setVAT(e.target.value)
    const onContactTypeChange = e => setContactType(e.target.value)

    const onProductsChange = (event) => {
        const { value, checked } = event.target;
        setProducts(prev => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter(item => item !== value);
            }
        });
    };

    const [facebook, setFacebook] = useState()
    const [instagram, setInstagram] = useState()
    const [tiktok, setTikTok] = useState()

    const onFacebookChange = e => setFacebook(e.target.value)
    const onInstagramChange = e => setInstagram(e.target.value)
    const onTikTokChange = e => setTikTok(e.target.value)

    const [showScheduleModal, setScheduleModal] = useState(false)
    const [selectedScheduleStore, setSelectedScheduleStore] = useState(null)

    {/* Update store 
        Create and display an error if no permissions
        */}

    const [updateStore, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateStoreMutation()

    const [permissionError, setPermissionError] = useState(false)

    const onUpdateStore = async (e) => {
        e.preventDefault()
        if (selectedStore.userID == u_id || Array.isArray(roles) && roles.includes("admin")) {
            await updateStore({ id, prefecture, city, storeTitle, entryType, storeType, coffee, consumption, storeOwner, contactPhone, contactEmail, storeName, VAT, contactType, products })
        } else {
            setPermissionError(true)
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleCloseView} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className='card-header'>{store.storeTitle}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={onUpdateStore}>
                    <Modal.Body>
                        <h4>Βασικές Πληροφορίες</h4>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={4} controlId="prefecture">
                                <Form.Label>Νομός</Form.Label>
                                <Form.Control type="text" name='prefecture' value={prefecture} onChange={onPrefectureChange} placeholder="Νομός" required />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="city">
                                <Form.Label>Πόλη</Form.Label>
                                <Form.Control type="text" name='city' value={city} onChange={onCityChange} placeholder="Πόλη" required />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="storeTitle">
                                <Form.Label>Όνομα Καταστήματος</Form.Label>
                                <Form.Control type="text" name='storeTitle' value={storeTitle} onChange={onStoreTitleChange} placeholder="Όνομα Καταστήματος" required />
                            </Form.Group>
                        </Row>
                        <h4>Πληροφορίες Καταχώρησης</h4>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={3} controlId="entryType">
                                <Form.Label>Καταχώρηση Από</Form.Label>
                                <Form.Select aria-label="entryType" name='entryType' value={entryType} onChange={onEntryTypeChange} required>
                                    <option key='' value=''>Επιλέξτε Τύπο Καταχώρησης</option>
                                    <option key='ΧΑΡΤΟΓΡΑΦΗΣΗ' value='ΧΑΡΤΟΓΡΑΦΗΣΗ'>Χαρτογράφηση</option>
                                    <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ'>Απευθείας Επικοινωνία</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={3} controlId="storeType">
                                <Form.Label>Τύπος Καταστήματος</Form.Label>
                                <Form.Select aria-label="storeType" name='storeType' value={storeType} onChange={onStoreTypeChange} required>
                                    <option key='' value=''>Επιλέξτε Τύπο Καταστήματος</option>
                                    {storeTypesList}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={3} controlId="coffee">
                                <Form.Label>Καφές</Form.Label>
                                <Form.Control type="text" name='coffee' value={coffee} onChange={onCoffeeChange} placeholder="Καφές" required />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={3} controlId="consumption">
                                <Form.Label>Κατανάλωση</Form.Label>
                                <Form.Control type="number" name='consumption' value={consumption} onChange={onConsumptionChange} placeholder="Κατανάλωση" required />
                            </Form.Group>
                        </Row>
                        <h4>Στοιχεία Επικοινωνίας</h4>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={4} controlId="storeOwner">
                                <Form.Label>Ιδιοκτήτης Καταστήματος</Form.Label>
                                <Form.Control type="text" name='storeOwner' value={storeOwner} onChange={onStoreOwnerChange} placeholder="Ιδιοκτήτης Καταστήματος" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="contactPhone">
                                <Form.Label>Τηλέφωνο Επικοινωνίας</Form.Label>
                                <Form.Control type="number" name='contactPhone' value={contactPhone} onChange={onContactPhoneChange} placeholder="Τηλέφωνο Επικοινωνίας" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="contactEmail">
                                <Form.Label>Email Επικοινωνίας</Form.Label>
                                <Form.Control type="email" name='contactEmail' value={contactEmail} onChange={onContactEmailChange} placeholder="Email Επικοινωνίας" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={4} controlId="storeOwner">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control type="text" name='storeOwner' value={facebook} onChange={onFacebookChange} placeholder="Facebook" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="contactPhone">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control type="number" name='contactPhone' value={instagram} onChange={onInstagramChange} placeholder="Instagram" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="contactEmail">
                                <Form.Label>Tik Tok</Form.Label>
                                <Form.Control type="email" name='contactEmail' value={tiktok} onChange={onTikTokChange} placeholder="TikTok" />
                            </Form.Group>
                        </Row>
                        <h4>Εκδήλωση Ενδιαφέροντος</h4>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={4} controlId="storeName">
                                <Form.Label>Επωνυμία Καταστήματος</Form.Label>
                                <Form.Control type="text" name='storeName' value={storeName} onChange={onStoreNameChange} placeholder="Επωνυμία Καταστήματος" />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="VAT">
                                <Form.Label>Α.Φ.Μ.</Form.Label>
                                <Form.Control type="text" name='VAT' value={VAT} onChange={onVatChange} placeholder="Α.Φ.Μ." />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4} controlId="contactType">
                                <Form.Label>Επαφή Με Την Εταιρεία</Form.Label>
                                <Form.Select aria-label="contactType" name='contactType' value={contactType} onChange={onContactTypeChange}>
                                    <option key='' value=''>Επιλεξτε Τύπο Επαφής</option>
                                    <option key='ΣΥΣΤΑΣΗ' value='ΜΕ ΣΥΣΤΑΣΗ'>ΜΕ ΣΥΣΤΑΣΗ</option>
                                    <option key='ΔΟΚΙΜΗ' value='ΜΕ ΔΟΚΙΜΗ'>ΜΕ ΔΟΚΙΜΗ</option>
                                    <option key='ΔΙΑΦΗΜΗΣΗ' value='ΜΕ ΔΙΑΦΗΜΗΣΗ'>ΜΕ ΔΙΑΦΗΜΗΣΗ</option>
                                    <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗ'>ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗ</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} controlId='products'>
                                <Form.Label>Προιόντα Ενδιαφέροντος</Form.Label>

                                <Form.Group as={Col} xs={12}>
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="espresso"
                                        name="espresso"
                                        value='ESPRESSO'
                                        onChange={onProductsChange}
                                        label="Espresso"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="decaf"
                                        name="decaf"
                                        value='DECAF'
                                        onChange={onProductsChange}
                                        label="Decaf"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="filter-coffee"
                                        name="filter-coffee"
                                        value='Καφές Φίλτρου'
                                        onChange={onProductsChange}
                                        label="Καφές Φίλτρου"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="single-origin"
                                        name="single-origin"
                                        value='Single Origin'
                                        onChange={onProductsChange}
                                        label="Single Origin"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="greek-coffee"
                                        name="greek-coffee"
                                        value='Ελληνικός Καφές'
                                        onChange={onProductsChange}
                                        label="Ελληνικός Καφές"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="chocolate"
                                        name="chocolate"
                                        value='Σοκολάτα Κλασική'
                                        onChange={onProductsChange}
                                        label="Σοκολάτα Κλασική"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="chocolate-flavors"
                                        name="chocolate-flavors"
                                        value='Σοκολάτα Γεύσεις'
                                        onChange={onProductsChange}
                                        label="Σοκολάτα Γεύσεις"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="tea-flavors"
                                        name="tea-flavors"
                                        value='Τσάι Γεύσεις'
                                        onChange={onProductsChange}
                                        label="Τσάι Γεύσεις"
                                    />
                                    <Form.Check // prettier-ignore
                                        inline
                                        id="tea-bio"
                                        name="tea-bio"
                                        value='Τσάι Βιολογικά'
                                        onChange={onProductsChange}
                                        label="Τσάι Βιολογικά"
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='w-100'>
                            {isSuccess && (
                                <Alert variant="success" className="w-100 text-center">
                                    Το κατάστημα ενημερώθηκε με επιτυχία!
                                </Alert>
                            )}

                            {permissionError && (
                                <Alert variant="danger" className="w-100 text-center">
                                    Δεν έχετε δικαιώματα να επεξεργαστείτε αυτό το κατάστημα.
                                </Alert>
                            )}

                            <div className='d-flex flex-column flex-md-row justify-content-between w-100 mt-2'>
                                <div className="mb-2 mb-md-0">
                                    Καταχωρήθηκε από: <b>{selectedStore.userID?.username}</b>
                                </div>
                                <div className='d-flex flex-column flex-md-row'>
                                    <Button className='cancel-btn me-md-2 mb-2 mb-md-0' onClick={handleCloseView}>
                                        Άκυρο
                                    </Button>
                                    <Button type='submit' className='primary-btn'>
                                        Αποθήκευση Αλλαγών
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </Form>
            </Modal >
        </>
    );
}

export default StoreCard