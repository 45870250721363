import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const projectsAdapter = createEntityAdapter({})
const initialState = projectsAdapter.getInitialState()

export const projectsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getprojects: builder.query({
            query: () => '/projects',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedProjects = responseData.map(projects => {
                    projects.id = projects._id
                    return projects
                })
                return projectsAdapter.setAll(initialState, loadedProjects)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'projects', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'projects', id }))
                    ]
                } else return [{type: 'projects', id: 'LIST'}]
            }
        }),
        addToProjects: builder.mutation({
            query: initialProjectsData => ({
                url: '/projects',
                method: 'POST',
                body: {
                    ...initialProjectsData,
                }
            }),
            invalidatesTags: [
                { type: 'projects', id: 'LIST' }
            ]
        }),
        updateProject: builder.mutation({
            query: initialItem => ({
                url: '/projects',
                method: 'PATCH',
                body: {
                    ...initialItem,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'projects', id: arg.id }
            ]
        }),
        addTask: builder.mutation({
            query:initialTaskData => ({
                url: '/projects/addTask',
                method: 'POST',
                body: {
                    ...initialTaskData
                }
            }),
            invalidatesTags: [
                { type: 'projects', id: 'LIST' }
            ]
        }),
        updateTaskStatus: builder.mutation({
            query: initialItem => ({
                url: '/projects/task/updateTaskStatus',
                method: 'PATCH',
                body: {
                    ...initialItem
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'projects', id: arg.id }
            ]
        })
    })
})

export const {
    useGetprojectsQuery,
    useAddToProjectsMutation,
    useUpdateProjectMutation,
    useAddTaskMutation,
    useUpdateTaskStatusMutation
} = projectsApiSlice

export const selectProjectsResult = projectsApiSlice.endpoints.getprojects.select();

const selectProjectsData = createSelector(
    selectProjectsResult,
    projectsResult => projectsResult.data
)


