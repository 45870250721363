
import React, { useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useGetCoffeeStoresQuery, useGetStoreSamplingsQuery, useGetStoreVisitsQuery } from '../coffeeStores/storesApiSlice'
import AddButton from './AddButton'
import { useGetChecklistsQuery } from '../checklists/checklistsApiSlice'

const Overview = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')

    const {
        data: checklistData,
        isSuccess: checklistsSuccess
    } = useGetChecklistsQuery()

    const {
        data: visitData,
        isSuccess: visitSuccess
    } = useGetStoreVisitsQuery()
    const {
        data: samplingData,
        isSuccess: samplingSuccess
    } = useGetStoreSamplingsQuery()

    const {
        data: coffeeStores,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetCoffeeStoresQuery({ page: currentPage, limit: 10, search: searchQuery }, {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    return (
        <Container className='overflow-hidden'>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start mt-4">
                <div className="mb-3 mb-md-0">
                    <h2>Επισκόπηση</h2>
                </div>
                <div className="w-100 w-md-auto mt-3 mt-md-0 d-flex justify-content-md-end">
                    <AddButton className="w-100" />
                </div>
            </div>




            {/* Data containers */}

            <Row className='mt-2'>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Τελευταίες Επισκέψεις
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                        <th>Πόλη</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {visitSuccess && visitData?.ids?.map((id) => (
                                        <tr key={id}>
                                            <td>{new Date(visitData?.entities?.[id]?.visitDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{visitData?.entities?.[id]?.storeName}</td>
                                            <td>{visitData?.entities?.[id]?.city}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2 '>
                    <div className='content-wrapper h-100'>
                        <div className='content-wrapper-header'>
                            Προγραμματισμένες Επισκέψεις
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                        <th>Τύπος</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Τελευταίοι Δειγματισμοί
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                        <th>Πόλη</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {samplingSuccess && samplingData?.ids?.map((id) => (
                                        <tr key={id}>
                                            <td>{new Date(samplingData?.entities?.[id]?.samplingDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{samplingData?.entities?.[id]?.storeName}</td>
                                            <td>{samplingData?.entities?.[id]?.city}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Τελευταία Checklists
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {checklistsSuccess && checklistData?.ids?.map((id) => (
                                        <tr key={id}>
                                            <td>{new Date(checklistData?.entities?.[id]?.date).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{checklistData?.entities?.[id]?.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Προγραμματισμένα Checklists
                        </div>
                        <div className='content-data pt-4'>
                            Δεν υπάρχουν Διαθέσιμα Δεδομένα
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Overview