import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import Modal from '../../components/Modal'
import Form from 'react-bootstrap/Form';
import { Button, Col, Row, Container } from 'react-bootstrap'


const Login = () => {
  const userRef = useRef()
  const errRef = useRef()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [persist, setPersist] = usePersist()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { isLoading }] = useLoginMutation()

  useEffect(() => {
    userRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [username, password])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const { accessToken } = await login({ username, password }).unwrap()
      dispatch(setCredentials({ accessToken }))
      setUsername('')
      setPassword('')
      navigate('/dashboard')
    } catch (err) {
      setShowErrorModal(true);
      if (!err.status) {
        setErrMsg('No Server Response')
      } else if (err.status === 400) {
        setErrMsg('Λάθος όνομα η κωδικός Χρήστη.')
      } else if (err.status === 401) {
        setErrMsg('Δεν βρέθηκε λογαριασμός με αυτό το όνομα χρήστη.')
      } else {
        setErrMsg(err.data?.message)
      }
      errRef.current.focus();
    }
  }


  const handleUserInput = e => setUsername(e.target.value)
  const handlePwdInput = e => setPassword(e.target.value)
  const handleToggle = () => setPersist(prev => !prev)

  if (isLoading) return <p>Loading...</p>

  const content = (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <img src="../../img/logodark.png" height={45} alt="Logo" />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="text" name="username" ref={userRef} value={username} onChange={handleUserInput} autoComplete="off" required placeholder="Όνομα Χρήστη" />
              <Form.Text className="text-muted">Πληκτρολογείστε το όνομα χρήστη.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" onChange={handlePwdInput} value={password} required placeholder="Password" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="persist">
              <Form.Check type="checkbox" name="persist" onChange={handleToggle} checked={persist} label="Να με θυμάσαι" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Σύνδεση
            </Button>
          </Form>
          <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)}>
            <p>Σφάλμα: {errMsg}</p>
          </Modal>
        </Col>
      </Row>
    </Container>
  )


  return content
}

export default Login