import React, { useState } from 'react';
import { Tabs, Tab, Container, Form, Col, Row, ButtonGroup, ToggleButton } from 'react-bootstrap';

const ChecklistForm2 = () => {
    const [activeStep, setActiveStep] = useState('step1');

    const FORM_FIELDS = [
        { type: 'text', name: 'name', label: 'Όνομα Πελάτη', required: true },
        { type: 'text', name: 'address', label: 'Διευθυνση Πελάτη', required: true },
        { type: 'number', name: 'phoneNumber', label: 'Τηλέφωνο Επικοινωνίας', required: true },
        { type: 'email', name: 'email', label: 'email', required: true },
    ]

    const FORM_FIELDS_ESPRESSO_MACHINE = [
        { type: 'text', name: 'manufacturer', label: 'Κατασκευαστής', required: true },
        { type: 'text', name: 'model', label: 'Μοντέλο', required: true },
        { type: 'text', name: 'serialNo', label: 'Serial Number', required: true },
        { type: 'number', name: 'groupNo', label: 'Αριθμός Group', value: 1, required: true },
    ]

    const FORM_FIELDS_GRINDER = [
        { type: 'text', name: 'grinderManufacturer', label: 'Κατασκευαστής', required: true },
        { type: 'text', name: 'grinderModel', label: 'Μοντέλο', required: true },
        { type: 'text', name: 'grinderSerialNo', label: 'Serial Number', required: true },
    ]

    const FORM_FIELDS_ESPRESSO_MACHINE_TECHNICAL = [
        { type: 'number', name: 'pumpPressure', label: 'Πίεση Αντλίας', required: true },
        { type: 'number', name: 'standByPressure', label: 'Πίεση Αναμονής', required: true },
        { type: 'number', name: 'boilerPressure', label: 'Πίεση Boiler', required: true },
    ]

    const FORM_FIELDS_ESPRESSO_MACHINE_GROUP = [
        { type: 'number', name: 'flowRate', label: 'Flow Rate', required: true },
        { type: 'number', name: 'temperature', label: 'Θερμοκρασία', required: true },
        { type: 'number', name: 'but1Total', label: 'BUTTON 1 TOTAL', required: false },
        { type: 'number', name: 'but2Total', label: 'BUTTON 2 TOTAL', required: false },
        { type: 'number', name: 'but3Total', label: 'BUTTON 3 TOTAL', required: false },
        { type: 'number', name: 'but4Total', label: 'BUTTON 4 TOTAL', required: false },
    ]

    const handleSelect = (step) => {
        setActiveStep(step);
    };

    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send formData to an API
        console.log('Form Data:', formData);
    };

    return (
        <div className="multi-step-form">
            <Container className="form-content">
                <Row className='mt-2'>
                    <Col className='mt-2' sm={12}>
                        {activeStep === 'general' &&
                            <div className='content-wrapper'>
                                <div className='content-wrapper-header'>
                                    Στοιχεία Πελάτη
                                </div>
                                <Form onSubmit={handleSubmit}>
                                    {FORM_FIELDS.map((field) => (
                                        <Form.Group as={Col} controlId={field.name}>
                                            <Form.Label>{field.label}(*)</Form.Label>
                                            <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} required />
                                        </Form.Group>
                                    ))}

                                    <Form.Group as={Col} controlId='type'>
                                        <Form.Label>Τύπος</Form.Label>
                                        <Form.Select aria-label="type" name="type" value={formData['type']} onChange={handleChange} required>
                                            <option value="KOLEKTIVA">KOLEKTIVA</option>
                                            <option value="SINGLE ROAST">SINGLE ROAST</option>
                                            <option value="ESPRESSONIST">ESPRESSONIST</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </div>}

                        {activeStep === 'step2' &&
                            <div className='content-wrapper'>
                                <div className='content-wrapper-header'>
                                    Στοιχεία Πελάτη
                                </div>
                                <Form onSubmit={handleSubmit}>
                                    {FORM_FIELDS_ESPRESSO_MACHINE.map((field) => (
                                        <Form.Group as={Col} controlId={field.name}>
                                            <Form.Label>{field.label}(*)</Form.Label>
                                            <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} required />
                                        </Form.Group>
                                    ))}

                                    <div className='content-wrapper-header'>
                                        Τεχνικός Έλεγχος Μηχανής
                                    </div>

                                    {FORM_FIELDS_ESPRESSO_MACHINE_TECHNICAL.map((field) => (
                                        <Form.Group as={Col} controlId={field.name}>
                                            <Form.Label>{field.label}(*)</Form.Label>
                                            <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} required />
                                        </Form.Group>
                                    ))}

                                    <div className='content-wrapper-header'>
                                        Steamwands
                                    </div>
                                    <Form.Group as={Col}>
                                        <Form.Label>Steamwand Αριστερα ΚΑΘΑΡΙΟΤΗΤΑ</Form.Label>
                                        <ButtonGroup toggle>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftClean' value={'Ναι'} >Ναί</ToggleButton>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftClean' value={'Όχι'}  >Όχι</ToggleButton>
                                        </ButtonGroup>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Steamwand Αριστερα Σωστή Λειτουργία</Form.Label>
                                        <ButtonGroup toggle>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftOperational' value={'Ναι'} >Ναί</ToggleButton>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftOperational' value={'Όχι'} >Όχι</ToggleButton>
                                        </ButtonGroup>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Steamwand Δεξιά ΚΑΘΑΡΙΟΤΗΤΑ</Form.Label>
                                        <ButtonGroup toggle>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandRightClean' value={'Ναι'} >Ναί</ToggleButton>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandRightClean' value={'Όχι'} >Όχι</ToggleButton>
                                        </ButtonGroup>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Steamwand Αριστερα Σωστή Λειτουργία</Form.Label>
                                        <ButtonGroup toggle>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandRightOperational' value={'Ναι'} >Ναί</ToggleButton>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandRightOperational' value={'Όχι'} >Όχι</ToggleButton>
                                        </ButtonGroup>
                                    </Form.Group>


                                    <div className='content-wrapper-header'>
                                        GROUP 1
                                    </div>

                                    {FORM_FIELDS_ESPRESSO_MACHINE_GROUP.map((field) => (
                                        <Form.Group as={Col} controlId={field.name}>
                                            <Form.Label>{field.label}(*)</Form.Label>
                                            <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} required />
                                        </Form.Group>
                                    ))}
                                    <Form.Group as={Col}>
                                        <Form.Label>Gusket/Showers</Form.Label>
                                        <ButtonGroup toggle>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftClean' value={'Ναι'} >Ναί</ToggleButton>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftClean' value={'Όχι'}  >Όχι</ToggleButton>
                                        </ButtonGroup>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>PORTAFILTER/FILTER BASKET</Form.Label>
                                        <ButtonGroup toggle>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftClean' value={'Ναι'} >Ναί</ToggleButton>
                                            <ToggleButton type='radio' variant='outline-primary' name='steamWandLeftClean' value={'Όχι'} >Όχι</ToggleButton>
                                        </ButtonGroup>
                                    </Form.Group>
                                </Form>
                            </div>}

                        {activeStep === 'step3' && <div>Step 3 Content</div>}
                        {activeStep === 'step4' && <div>Step 4 Content</div>}
                    </Col>
                </Row>

            </Container>
            <Tabs
                activeKey={activeStep}
                onSelect={(k) => handleSelect(k)}
                className="mobile-nav"
            >
                <Tab eventKey="general" title="Γενικές Πληροφορίες" />
                <Tab eventKey="step2" title="Μηχανή Καφέ" />
                <Tab eventKey="step3" title="Μύλος Άλεσης" />
                <Tab eventKey="step4" title="Φιλτρανση" />
            </Tabs>
        </div>
    );
}

export default ChecklistForm2