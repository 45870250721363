import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useGetUsersQuery } from '../users/userApiSlice';
import useAuth from '../../hooks/useAuth';
import { useAddToUserScheduleMutation } from './userScheduleApiSlice';

const AddToSchedule = ({ show, handleCloseView, selectedStore }) => {

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList')

    const [addToUserSchedule, {
        isLoading: scheduleLoading,
        isSuccess: scheduleSuccess
    }] = useAddToUserScheduleMutation()

    const {username: currentUser} = useAuth()
    const [usernames, setUsernames] = useState([]);
    const [taskFields, setTaskFields] = useState({ date: '', user: currentUser, storeName: selectedStore?.storeTitle, type: 'ΕΠΙΣΚΕΨΗ', comments: '' })

    useEffect(() => {
        if (isSuccess) {
            // Extract usernames while filtering out 'test'
            const filteredUsernames = users.ids
                .map(user => users.entities[user].username)
                .filter(username => username !== 'test');

            setUsernames(filteredUsernames);
        }
    }, [isSuccess, users]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setTaskFields({ ...taskFields, [name]: value })
    }

    const onAddToUserSchedule = async (e) => {
        e.preventDefault()
        try{
            await addToUserSchedule(taskFields)
        } catch (err) {
            console.error(err)
        }
    }


    return (
        <>
            <Modal show={show} onHide={handleCloseView} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedStore.storeTitle}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={onAddToUserSchedule}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Ημερομηνία</Form.Label>
                                <Form.Control type='date' name='date' value={taskFields['date']} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Πωλητής</Form.Label>
                                <Form.Control as="select" name='user' value={taskFields['user']} onChange={handleChange} required>
                                    {isLoading && <option>Loading users...</option>}
                                    {isError && <option>Error loading users</option>}
                                    {isSuccess && usernames.length > 0 ? (
                                        usernames.map((user) => (
                                            <option key={user} value={user}>
                                                {user}
                                            </option>
                                        ))
                                    ) : (
                                        <option>No users available</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={4}>
                                <Form.Label>Τύπος</Form.Label>
                                <Form.Control as="select" name='type' value={taskFields['type']} onChange={handleChange} required>
                                    <option key='ΕΠΙΣΚΕΨΗ' value='ΕΠΙΣΚΕΨΗ'>ΕΠΙΣΚΕΨΗ</option>
                                    <option key='ΔΕΙΓΜΑΤΙΣΜΟΣ' value='ΔΕΙΓΜΑΤΙΣΜΟΣ'>ΔΕΙΓΜΑΤΙΣΜΟΣ</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={12}>
                                <Form.Label>Σχόλια</Form.Label>
                                <Form.Control type='text' name='comments' value={taskFields['comments']} onChange={handleChange} required />
                            </Form.Group>
                        </Row>
                        <Button type='submit' className='primary-btn mt-3'>Αποθήκευση</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddToSchedule