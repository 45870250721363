import React, { useEffect } from 'react'
import { useAddNewCoffeeStoreMutation } from './storesApiSlice'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons'
import { storeTypesList } from '../../config/storeTypes'
import useAuth from '../../hooks/useAuth'
import { Container, Button, Col, Form, Modal, Row } from 'react-bootstrap'

const NewStoreForm = () => {

    const [addNewStore, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCoffeeStoreMutation()

    const navigate = useNavigate()
    const { username } = useAuth()

    const [prefecture, setPrefecture] = useState('')
    const [city, setCity] = useState('')
    const [storeTitle, setStoreTitle] = useState('')
    const [entryType, setEntryType] = useState('')
    const [storeType, setStoreType] = useState('')
    const [user, setUser] = useState(username)
    const [coffee, setCoffee] = useState('')
    const [consumption, setConsumption] = useState('')
    const [storeOwner, setStoreOwner] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')

    // Visit Form Inputs

    const [visitDate, setVisitDate] = useState('')
    const [visitType, setVisitType] = useState('')
    const [comments, setComments] = useState('')
    const [vUser, setVUser] = useState(username)

    const onCommentsChange = e => setComments(e.target.value)
    const onVisitTypeChange = e => setVisitType(e.target.value)
    const onVisitDateChange = e => setVisitDate(e.target.value)

    const [showVisitInputs, setShowVisitInputs] = useState(false)
    const toggleVisitInputs = () => setShowVisitInputs(!showVisitInputs)

    const visit = { visitDate, visitType, comments }

    // Sampling Form Inputs

    const [samplingDate, setSamplingDate] = useState('')
    const [samplingCoffee, setSamplingCoffeeCoffee] = useState('')
    const [samplingComments, setSamplingComments] = useState('')

    const onSamplingCoffeeChange = e => setSamplingCoffeeCoffee(e.target.value)
    const onSamplingCommentsChange = e => setSamplingComments(e.target.value)
    const onSamplingDateChange = e => setSamplingDate(e.target.value)

    const [showSamplingInputs, setShowSamplingInputs] = useState(false)
    const toggleSamplingInputs = () => setShowSamplingInputs(!showSamplingInputs)

    const sampling = { samplingDate, samplingCoffee, samplingComments }

    // Equipment Form Inputs

    const [espressoMachine, setEspressoMachine] = useState('')
    const [numOfGroups, setNumOfGroups] = useState('')
    const [machineCondition, setMachineCondition] = useState('')
    const [grinder, setGrinder] = useState('')
    const [grinderCondition, setGrinderCondition] = useState('')

    const onEspressoMachineChange = e => setEspressoMachine(e.target.value)
    const onNumOfGroupsChange = e => setNumOfGroups(e.target.value)
    const onMachineConditionChange = e => setMachineCondition(e.target.value)
    const onGrinderChange = e => setGrinder(e.target.value)
    const onGrinderConditionChange = e => setGrinderCondition(e.target.value)

    const [showEquipmentInputs, setShowEquipmentInputs] = useState(false)
    const toggleEquipmentInputs = () => setShowEquipmentInputs(!showEquipmentInputs)

    const equipment = { espressoMachine, numOfGroups, machineCondition, grinder, grinderCondition }

    // Interest Form

    const [storeName, setStoreName] = useState('')
    const [VAT, setVAT] = useState('')
    const [contactType, setContactType] = useState('')
    const [products, setProducts] = useState([])

    const onStoreNameChange = e => setStoreName(e.target.value)
    const onVatChange = e => setVAT(e.target.value)
    const onContactTypeChange = e => setContactType(e.target.value)

    const onProductsChange = (event) => {
        const { value, checked } = event.target;
        setProducts(prev => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter(item => item !== value);
            }
        });
    };

    const [showInterestInputs, setShowInterestInputs] = useState(false)
    const toggleInterestInputs = () => setShowInterestInputs(!showInterestInputs)

    const interest = { storeName, VAT, contactType, products }

    useEffect(() => {
        setUser(username);
    }, [username]);

    useEffect(() => {
        if (isSuccess) {
            setPrefecture('')
            setCity('')
            setStoreTitle('')
            setEntryType('')
            setStoreType('')
            setUser('')
            setCoffee('')
            setConsumption('')
            setStoreOwner('')
            setContactPhone('')
            setContactEmail('')
            navigate('/dashboard/coffeestores', { state: { message: 'Το κατάστημα προστέθηκε στη λίστα' } })
        }
    }, [isSuccess, navigate])

    const onPrefectureChange = e => setPrefecture(e.target.value)
    const onCityChange = e => setCity(e.target.value)
    const onStoreTitleChange = e => setStoreTitle(e.target.value)
    const onEntryTypeChange = e => setEntryType(e.target.value)
    const onStoreTypeChange = e => setStoreType(e.target.value)
    const onCoffeeChange = e => setCoffee(e.target.value)
    const onConsumptionChange = e => setConsumption(e.target.value)
    const onStoreOwnerChange = e => setStoreOwner(e.target.value)
    const onContactPhoneChange = e => setContactPhone(e.target.value)
    const onContactEmailChange = e => setContactEmail(e.target.value)

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Handle Form Submit
    const onAddNewStore = async (e) => {
        e.preventDefault()
        try {
            await addNewStore({ prefecture, city, storeTitle, entryType, storeType, user, consumption, coffee, storeOwner, contactPhone, contactEmail, visit, sampling, equipment, interest })
        } catch (error) {
            const errorMessage = error.data?.message || 'An unexpected error occurred';
            setErrorMessage(errorMessage);
            setShowErrorModal(true);
        }
    }

    let content

    if (isLoading) {
        content = <p>Loading...</p>
    }

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    content = (
        <>
            <Button className='primary-btn' type="button" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} /> Προσθήκη Καταστήματος
            </Button>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Container>

                    <Modal.Header closeButton>
                        <Modal.Title>Προσθήκη Νέου Καταστήματος</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={onAddNewStore}>

                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="prefecture">
                                <Form.Label>Νομός(*)</Form.Label>
                                <Form.Control type="text" name='prefecture' value={prefecture} onChange={onPrefectureChange} placeholder="Νομός" required />
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>Πόλη(*)</Form.Label>
                                <Form.Control type="text" name='city' value={city} onChange={onCityChange} placeholder="Πόλη" required />
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="storeTitle">
                                <Form.Label>Όνομα Καταστήματος(*)</Form.Label>
                                <Form.Control type="text" name='storeTitle' value={storeTitle} onChange={onStoreTitleChange} placeholder="Όνομα Καταστήματος" required />
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="entryType">
                                <Form.Label>Καταχώρηση Από(*)</Form.Label>
                                <Form.Select aria-label="entryType" name='entryType' value={entryType} onChange={onEntryTypeChange} required>
                                    <option key='' value=''>Επιλέξτε Τύπο Καταχώρησης</option>
                                    <option key='ΧΑΡΤΟΓΡΑΦΗΣΗ' value='ΧΑΡΤΟΓΡΑΦΗΣΗ'>Χαρτογράφηση</option>
                                    <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ'>Απευθείας Επικοινωνία</option>
                                </Form.Select>
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="storeType">
                                <Form.Label>Τύπος Καταστήματος(*)</Form.Label>
                                <Form.Select aria-label="storeType" name='storeType' value={storeType} onChange={onStoreTypeChange} required>
                                    <option key='' value=''>Επιλέξτε Τύπο Καταστήματος</option>
                                    {storeTypesList}
                                </Form.Select>
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="coffee">
                                <Form.Label>Καφές(*)</Form.Label>
                                <Form.Control type="text" name='coffee' value={coffee} onChange={onCoffeeChange} placeholder="Καφές" required />
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="consumption">
                                <Form.Label>Κατανάλωση(*)</Form.Label>
                                <Form.Control type="number" name='consumption' value={consumption} onChange={onConsumptionChange} placeholder="Κατανάλωση" required />
                                <Form.Text className="text-muted">
                                    Το πεδίο είναι υποχρεωτικό
                                </Form.Text>
                            </Form.Group>

                            <h2>Στοιχεία Επικοινωνίας</h2>

                            <Form.Group className="mb-3" controlId="storeOwner">
                                <Form.Label>Ιδιοκτήτης Καταστήματος</Form.Label>
                                <Form.Control type="text" name='storeOwner' value={storeOwner} onChange={onStoreOwnerChange} placeholder="Ιδιοκτήτης Καταστήματος" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="contactPhone">
                                <Form.Label>Τηλέφωνο Επικοινωνίας</Form.Label>
                                <Form.Control type="number" name='contactPhone' value={contactPhone} onChange={onContactPhoneChange} placeholder="Τηλέφωνο Επικοινωνίας" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="contactEmail">
                                <Form.Label>Email Επικοινωνίας</Form.Label>
                                <Form.Control type="email" name='contactEmail' value={contactEmail} onChange={onContactEmailChange} placeholder="Email Επικοινωνίας" />
                            </Form.Group>


                            <div className="d-grid gap-2">
                                <Button className='secondary-btn' onClick={toggleVisitInputs}><FontAwesomeIcon icon={faCirclePlus} /> Προσθήκη Στοιχείων Επίσκεψης</Button>

                                {showVisitInputs && (
                                    <>
                                        <Form.Group className="mb-3" controlId="visitDate">
                                            <Form.Label>Ημερομηνία Επίσκεψης</Form.Label>
                                            <Form.Control type="date" name='visitDate' value={visitDate} onChange={onVisitDateChange} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="visitType">
                                            <Form.Label>Τύπος Επικοινωνίας</Form.Label>
                                            <Form.Select aria-label="visitType" name='visitType' value={visitType} onChange={onVisitTypeChange}>
                                                <option key='' value=''>Επιλεξτε Τύπο Επικοινωνίας</option>
                                                <option key='ΤΗΛΕΦΩΝΙΚΗ' value='ΤΗΛΕΦΩΝΙΚΗ'>Τηλεφωνική</option>
                                                <option key='ΕΠΙΣΚΕΨΗ' value='ΕΠΙΣΚΕΨΗ'>Επίσκεψη</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="comments">
                                            <Form.Label>Σχόλια</Form.Label>
                                            <Form.Control as="textarea" rows={4} name='comments' value={comments} onChange={onCommentsChange} autoComplete="off" />
                                        </Form.Group>
                                    </>
                                )}

                                <Button className='secondary-btn'  onClick={toggleInterestInputs}><FontAwesomeIcon icon={faCirclePlus} /> Προσθήκη Στοιχείων Ενδιαφέροντος</Button>
                                {showInterestInputs && (
                                    <>
                                        <Form.Group className="mb-3" controlId="storeName">
                                            <Form.Label>Επωνυμία Καταστήματος</Form.Label>
                                            <Form.Control type="text" name='storeName' value={storeName} onChange={onStoreNameChange} placeholder="Επωνυμία Καταστήματος" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="VAT">
                                            <Form.Label>Α.Φ.Μ.</Form.Label>
                                            <Form.Control type="text" name='VAT' value={VAT} onChange={onVatChange} placeholder="Α.Φ.Μ." />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="contactType">
                                            <Form.Label>Επαφή Με Την Εταιρεία</Form.Label>
                                            <Form.Select aria-label="contactType" name='contactType' value={contactType} onChange={onContactTypeChange}>
                                                <option key='' value=''>Επιλεξτε Τύπο Επαφής</option>
                                                <option key='ΣΥΣΤΑΣΗ' value='ΜΕ ΣΥΣΤΑΣΗ'>ΜΕ ΣΥΣΤΑΣΗ</option>
                                                <option key='ΔΟΚΙΜΗ' value='ΜΕ ΔΟΚΙΜΗ'>ΜΕ ΔΟΚΙΜΗ</option>
                                                <option key='ΔΙΑΦΗΜΗΣΗ' value='ΜΕ ΔΙΑΦΗΜΗΣΗ'>ΜΕ ΔΙΑΦΗΜΗΣΗ</option>
                                                <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗ'>ΑΠΕΥΘΕΙΑΣ ΠΩΛΗΣΗ</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className='mb-3' controlId='products'>
                                            <Form.Label>Προιόντα Ενδιαφέροντος</Form.Label>

                                            <Form.Group>
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="espresso"
                                                    name="espresso"
                                                    value='ESPRESSO'
                                                    onChange={onProductsChange}
                                                    label="Espresso"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="decaf"
                                                    name="decaf"
                                                    value='DECAF'
                                                    onChange={onProductsChange}
                                                    label="Decaf"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="filter-coffee"
                                                    name="filter-coffee"
                                                    value='Καφές Φίλτρου'
                                                    onChange={onProductsChange}
                                                    label="Καφές Φίλτρου"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="single-origin"
                                                    name="single-origin"
                                                    value='Single Origin'
                                                    onChange={onProductsChange}
                                                    label="Single Origin"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="greek-coffee"
                                                    name="greek-coffee"
                                                    value='Ελληνικός Καφές'
                                                    onChange={onProductsChange}
                                                    label="Ελληνικός Καφές"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="chocolate"
                                                    name="chocolate"
                                                    value='Σοκολάτα Κλασική'
                                                    onChange={onProductsChange}
                                                    label="Σοκολάτα Κλασική"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="chocolate-flavors"
                                                    name="chocolate-flavors"
                                                    value='Σοκολάτα Γεύσεις'
                                                    onChange={onProductsChange}
                                                    label="Σοκολάτα Γεύσεις"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="tea-flavors"
                                                    name="tea-flavors"
                                                    value='Τσάι Γεύσεις'
                                                    onChange={onProductsChange}
                                                    label="Τσάι Γεύσεις"
                                                />
                                                <Form.Check // prettier-ignore
                                                    inline
                                                    id="tea-bio"
                                                    name="tea-bio"
                                                    value='Τσάι Βιολογικά'
                                                    onChange={onProductsChange}
                                                    label="Τσάι Βιολογικά"
                                                />
                                            </Form.Group>

                                        </Form.Group>
                                    </>
                                )}

                                <Button className='secondary-btn'  onClick={toggleSamplingInputs}><FontAwesomeIcon icon={faCirclePlus} /> Προσθήκη Στοιχείων Δειγματισμού</Button>

                                {showSamplingInputs && (
                                    <>
                                        <Form.Group className="mb-3" controlId="samplingDate">
                                            <Form.Label>Ημερομηνία Δειγματισμού</Form.Label>
                                            <Form.Control type="date" name='samplingDate' value={samplingDate} onChange={onSamplingDateChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="samplingCoffee">
                                            <Form.Label>Προιόντα Δειγματισμού</Form.Label>
                                            <Form.Control type="text" name='samplingCoffee' value={samplingCoffee} onChange={onSamplingCoffeeChange} placeholder="Προιόντα Δειγματισμού" />
                                            <Form.Text className="text-muted">
                                                Χρησιμοποιήστε κόμμα(,) για να διαχωρίσετε τα προϊόντα.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="samplingComments">
                                            <Form.Label>Σχόλια Δειγματισμού</Form.Label>
                                            <Form.Control as="textarea" rows={4} name='samplingComments' value={samplingComments} onChange={onSamplingCommentsChange} autoComplete="off" />
                                        </Form.Group>
                                    </>
                                )}

                                <Button className='secondary-btn'  onClick={toggleEquipmentInputs}><FontAwesomeIcon icon={faCirclePlus} /> Προσθήκη Στοιχείων Εξοπλισμού</Button>

                                {showEquipmentInputs && (
                                    <>
                                        <Form.Group className='mb-3' controlId='espressoMachine'>
                                            <Form.Label>Μηχανή Καφέ</Form.Label>
                                            <Form.Control type="text" name='espressoMachine' value={espressoMachine} onChange={onEspressoMachineChange} placeholder="Μηχανή Καφέ" />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='numOfGroups'>
                                            <Form.Label>Αριθμός Group</Form.Label>
                                            <Form.Control type="number" name='numOfGroups' value={numOfGroups} onChange={onNumOfGroupsChange} />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='machineCondition'>
                                            <Form.Label>Κατάσταση Μηχανής</Form.Label>
                                            <Form.Control type="text" name='machineCondition' value={machineCondition} onChange={onMachineConditionChange} />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='grinder'>
                                            <Form.Label>Μύλος</Form.Label>
                                            <Form.Control type="text" name='grinder' value={grinder} onChange={onGrinderChange} />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId='grinderCondition'>
                                            <Form.Label>Κατάσταση Μύλου</Form.Label>
                                            <Form.Control type="text" name='grinderCondition' value={grinderCondition} onChange={onGrinderConditionChange} />
                                        </Form.Group>
                                    </>
                                )}
                            </div>

                            <Form.Group className='mb-3'>
                                <p><i>Τα πεδία με αστερίσκο (*) είναι υποχρεωτικά!</i></p>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type='button' className='cancel-btn' onClick={handleClose}>
                                Άκυρο
                            </Button>
                            <Button type='submit' className='primary-btn'>
                                Αποθήκευση
                            </Button>
                        </Modal.Footer>

                        <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)}>
                            <p>Σφάλμα: {errorMessage}</p>
                        </Modal>
                    </Form>
                </Container>
            </Modal >

        </>
    )

    return content
}

export default NewStoreForm