const storeTypes = [
    'ΚΑΦΕ - ΜΠΑΡ',
    'ΚΑΦΕΤΕΡΕΙΑ',
    'ΚΑΦΕ - TAKEAWAY',
    'RESTAURANT',
    'ΚΑΦΕ - RESTAURANT',
    'ΚΑΦΕ - ΖΑΧΑΡΟΠΛΑΣΤΕΙΟ',
    'ΚΑΦΕ - ΚΡΕΠΑ',
    'ΜΠΟΥΓΑΤΣΑ',
    'ΚΑΦΕΚΟΠΤΕΙΟ',
    'ΞΕΝΟΔΟΧΕΙΟ',
    'BEACH BAR',
    'ΕΜΠΟΡΟΣ - ΔΙΑΝΟΜΕΣ'
]

export const storeTypesList = storeTypes.map(storeType => {
    return (
        <option
            key={storeType}
            value={storeType}
        >{storeType}</option>
    )
})