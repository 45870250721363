import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSendLogoutMutation } from "../features/authentication/authApiSlice";
import useAuth from "../hooks/useAuth";
import MainNav from "./MainNav";
import "../assets/css/header.css"
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const Header = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate()

  const { username } = useAuth()

  const [sendLogout, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useSendLogoutMutation()

  useEffect(() => {
    if (isSuccess) navigate('/')
  }, [isSuccess, navigate])

  const onLogoutClicked = () => sendLogout()

  if (isLoading) return <p>Logging Out...</p>

  if (isError) return <p>Error: {error.data?.message}</p>


  return (
    <>
      <header id='page-topbar'>
        <div className='header-bar'>
          <div className='d-flex'>
            {!isMobile && (
              <img
                src='/img/logodark.png'
                height='30'
                className='d-inline-block align-top logo'
                alt='logo'
              />
            )}
            {isMobile && (
              <div className='mobile-nav'>
                <MainNav username={username} onLogoutClicked={onLogoutClicked} />
              </div>
            )}
          </div>

          <div className='d-flex align-items-center justify-content-end w-100'>
            <div className='me-3'>
              <Link to={'/dashboard/user_schedule'}>Πρόγραμμα</Link>
            </div>
            {!isMobile && (
              <>
                <div className='me-3'>

                  <NavDropdown title={<><FontAwesomeIcon icon={faCircleUser} /> {username}</>}>
                    <NavDropdown.Item href="/dashboard/user/edit">Αλλαγή Κωδικού</NavDropdown.Item>
                  </NavDropdown>
                </div>
                <div>
                  <Link to='#' onClick={onLogoutClicked}>Αποσύνδεση</Link>
                </div>
              </>
            )}


          </div>
        </div>
      </header>
      <div id='menu-bar'>
        {!isMobile && <MainNav />}
      </div>
    </>
  )
}

export default Header