import { useEffect, useState } from 'react'
import { useUpdateUserMutation } from './userApiSlice'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Form } from 'react-bootstrap'

const USER_REGEX = /^[A-z]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/

const EditUserForm = ({ user }) => {

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const navigate = useNavigate()

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess) {
            setPassword('')
            navigate('/dashboard')
        }
    }, [isSuccess, navigate])

    const onPasswordChanged = e => setPassword(e.target.value)

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (password) {
            await updateUser({ id: user._id, password })
        }
    }

    let canSave
    if (password) {
        canSave = [validPassword].every(Boolean) && !isLoading
    }

    let content

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    content = (
        <Container>
        <Form onSubmit={onSaveUserClicked}>

            <h2>Αλλαγή Κωδικού</h2>

            <Form.Group className="mb-3" controlId="password">
            <Form.Label>Νέος Κωδικός</Form.Label>
                <Form.Control 
                    type='password'
                    name="password"
                    value={password}
                    onChange={onPasswordChanged}
                    required
                />
            </Form.Group>
            <Button>Αποθήκευση</Button>
        </Form>
        </Container>
    )


    return content
}

export default EditUserForm