import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Nav, Offcanvas } from 'react-bootstrap'
import NewStoreForm from '../coffeeStores/NewStoreForm'
import NewFuel from '../fuels/NewFuel'
import AddToProjects from '../schedule/AddToProjects'


const AddButton = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Offcanvas
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const [showFuelModal, setShowFuelModal] = useState(false)

    const viewFuelModal = () => {
        setShowFuelModal(true)
    }

    const closeFuelModal = () => {
        setShowFuelModal(false)
    }
    return (
        <>
            <Button className={`primary-btn ${isMobile ? 'w-100' : ''}`} type="button" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} /> Προσθήκη
            </Button>

            <Offcanvas show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Προσθήκη</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className='mt-2'>
                        <div className='content-wrapper d-block'>
                            <NewStoreForm />
                            <div className='content-data pt-4'>
                                Άνοιγμα φόρμας προσθήκης καταστήματος
                            </div>
                        </div>
                        <div className='content-wrapper mt-4'>
                            <div className='content-wrapper-header d-block'>
                                <Nav.Link onClick={viewFuelModal} className=''>Καύσιμο</Nav.Link>
                            </div>
                            <div className='content-data pt-4'>
                                Άνοιγμα φόρμας προσθήκης καυσίμου
                            </div>
                        </div>
                        <div className='content-wrapper mt-4'>
                            <div className='content-wrapper-header d-block'>
                                <Nav.Link as={Link} to={''} className='isDisabled'>Checklist</Nav.Link>
                            </div>
                            <div className='content-data pt-4'>
                                Άνοιγμα φόρμας προσθήκης checklist
                            </div>
                        </div>
                        <div className='content-wrapper mt-4'>
                            <div className='content-wrapper-header d-block'>
                                <AddToProjects />
                            </div>
                            <div className='content-data pt-4'>
                                Άνοιγμα φόρμας προσθήκης Project
                            </div>
                        </div>
                    </Col>
                </Offcanvas.Body>
            </Offcanvas>

            {showFuelModal && (
                <NewFuel
                    show={showFuelModal}
                    handleClose={closeFuelModal}
                />
            )}
        </>
    )
}

export default AddButton