import React, { useEffect, useState } from 'react'
import { useGetUsersQuery } from '../users/userApiSlice';
import useAuth from '../../hooks/useAuth';
import { Col, Row, Container, Form, Button, Table } from "react-bootstrap"
import { useGetUserScheduleQuery } from './userScheduleApiSlice';

const UserScheduleList = () => {

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList')

  const { username: currentUser } = useAuth()
  const [usernames, setUsernames] = useState([]);
  const [user, setUser] = useState(currentUser)
  const [date, setDate] = useState()
  const [searchQuery, setSearchQuery] = useState({ user, date })

  const {
    data: scheduleData,
    isSuccess: scheduleSuccess
  } = useGetUserScheduleQuery(searchQuery)

  useEffect(() => {
    if (isSuccess) {
      // Extract usernames while filtering out 'test'
      const filteredUsernames = users.ids
        .map(user => users.entities[user].username)
        .filter(username => username !== 'test');

      setUsernames(filteredUsernames);
    }
  }, [isSuccess, users]);

  const onUserSet = e => setUser(e.target.value)
  const onDateChange = e => setDate(e.target.value)

  const handleSearch = () => {
    setSearchQuery({ user, date })
  }

  return (
    <>
      <Container className="overflow-hidden">
        <div className="d-flex justify-content-between mt-4">
          <div><h2>Διαχείριση Προγραμματος</h2></div>
        </div>
        {/* Start Of filter */}
        <Row>
          <Col lg={12}>
            <div className='card'>
              <div className='card-header'>
                <div className='d-flex align-items-center flex-wrap gap-2 mt-2 mb-2'>
                  <div className='flex-grow-1'>
                    <Form.Control type='date' name='date' value={date} onChange={onDateChange} />
                  </div>
                  <div className='flex-grow-1'>
                    <Form.Group as={Row} className="m-3" controlId="carPlate">
                      <Form.Label column xs={12} md={6}>Επιλογή Χρήστη</Form.Label>
                      <Col xs={12} md={6}>
                        <Form.Control as="select" name='user' value={user} onChange={onUserSet}>
                          {isLoading && <option>Loading users...</option>}
                          {isError && <option>Error loading users</option>}
                          {isSuccess && usernames.length > 0 ? (
                            usernames.map((user) => (
                              <option key={user} value={user}>
                                {user}
                              </option>
                            ))
                          ) : (
                            <option>No users available</option>
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>                    </div>
                  <div className='flex-grow-1'>
                    <Button className="primary-btn" onClick={handleSearch}>
                      Αναζήτηση
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* End Of filter */}

        {/* Start Of content table */}
        <Row className="mt-2">
          <Col sm={12} className="mt-2">
            <div className='content-wrapper'>
              <div className='content-data pt-4'>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ημερομηνία</th>
                      <th>Τύπος</th>
                      <th>Κατάστημα</th>
                      <th>Σχόλια</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleSuccess && (
                      scheduleData?.ids.map((id) => {
                        const scheduleEntity = scheduleData?.entities[id];
                        return (
                          <React.Fragment key={id}>
                            {scheduleEntity?.tasks.map((task, taskIndex) => (
                              <tr key={task.id || taskIndex}>
                                <td>{taskIndex + 1}</td>
                                <td>{new Date(scheduleEntity?.date).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                <td>{task.type}</td>
                                <td>{task.storeName}</td>
                                <td>{task.comments}</td>
                              </tr>
                            ))}
                          </React.Fragment>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default UserScheduleList