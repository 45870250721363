import { useGetFuelsQuery } from "./fuelsApiSlice"
import { CARS } from "../../config/cars"
import { useEffect, useState } from "react"
import calculateConsumption from "../../helpers/calculateConsumption"
import { useLocation, useNavigate } from "react-router-dom"
import Table from 'react-bootstrap/Table';
import { Col, Row, Container, Form, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faPlus, faPrint, faSquareCheck, faSquareXmark } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"
import EditFuel from "./EditFuel"
import NewFuel from "./NewFuel"

const FuelsList = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [carPlate, setCarPlate] = useState('ΕΡΙ 2176')
  const [searchQuery, setSearchQuery] = useState(carPlate)
  const { username } = useAuth()
  const [showEditModal, setShowEditModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false)
  const [selectedFuelId, setSelectedFuelId] = useState(null);

  // get Data from query
  const {
    data: fuelsData,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetFuelsQuery({ page: currentPage, limit: 20, search: searchQuery }, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const location = useLocation();
  const navigate = useNavigate()
  const [visibleMessage, setVisibleMessage] = useState(location.state?.message);

  const onCarPlateChange = e => {
    setCarPlate(e.target.value)
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to page 1 when search query changes
  }

  const nextPage = () => {
    if (fuelsData && currentPage < fuelsData.totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (fuelsData && currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  useEffect(() => {
    // If there's a message, set a timer to clear it after 10 seconds
    if (visibleMessage) {
      const timer = setTimeout(() => {
        setVisibleMessage(null); // Clears the message
      }, 10000); // 10 seconds

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [visibleMessage]);

  const cars = CARS.map(car => {
    return (
      <option
        key={car.carPlate}
        value={car.carPlate}
      >{car.carPlate}</option>
    )
  })

  let content

  if (isLoading) content = <p>Loading...</p>

  if (isError) {
    content = <p>{error?.data?.message}</p>
  }

  if (isSuccess) {
    const { ids, entities } = fuelsData.fuels;
    const { currentPage, totalPages } = fuelsData;

    const consumptionByFuelId = calculateConsumption(fuelsData.fuels)

    const handleNewClick = () => {
      setShowNewModal(true)
    }

    const handleNewModalClose = () => {
      setShowNewModal(false)
    }

    const handleEditClick = (fuelId) => {
      setSelectedFuelId(fuelId);
      setShowEditModal(true);
    };

    const handleModalClose = () => {
      setShowEditModal(false);
      setSelectedFuelId(null); // Reset the selected fuel ID when the modal is closed
    };

    const tableContent = ids?.length
      ? ids.map((fuelId, index) =>
        <tr key={fuelId}>
          <td>{(currentPage - 1) * 20 + index + 1}</td>
          <td>{new Date(entities[fuelId].fuelDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
          <td>{entities[fuelId].carPlate}</td>
          <td>{entities[fuelId].kilometers}</td>
          <td>{entities[fuelId].gasLiters}</td>
          <td>{entities[fuelId].gasPrice} €</td>
          <td>{entities[fuelId].moneyAmount} €</td>
          <td>{entities[fuelId].userID?.username}</td>
          <td>{entities[fuelId].gasFull ? <FontAwesomeIcon icon={faSquareCheck} /> : <FontAwesomeIcon icon={faSquareXmark} />}</td>
          <td>{consumptionByFuelId[fuelId] ? `${consumptionByFuelId[fuelId]} lt / 100 km` : '-'}</td>
          <td>{username === 'g.varsamis' ?
            <button className="secondary-btn" onClick={() => handleEditClick(fuelId)}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            :
            (index === 0 && entities[fuelId].userID?.username === username) && ( // Check if it's the last item
              <button className="secondary-btn" onClick={() => handleEditClick(fuelId)}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            )}</td>
        </tr>)
      : null

    content = (
      <Container className="overflow-hidden">
        {visibleMessage && <div className='successMessage'>{visibleMessage}</div>}

        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
          <h4>ΔΙΑΧΕΙΡΗΣΗ ΟΧΗΜΑΤΩΝ</h4>
        </div>
        <Row>
          <Col lg={12}>
            <div className="card">
              <div className="card-header">
                {/* Main responsive container */}
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between gap-2 mt-2 mb-2">
                  {/* Left side with two items */}
                  <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2">
                    {/* Add Fuel button */}
                    <Button className="secondary-btn" onClick={handleNewClick}>
                      <FontAwesomeIcon icon={faPlus} /> Προσθήκη Καυσίμου
                    </Button>

                    {/* Select Vehicle */}
                    <Form.Group as={Row} className="m-0" controlId="carPlate">
                      <Form.Label column xs={12} md="auto">
                        Επιλογή Οχήματος
                      </Form.Label>
                      <Col xs={12} md="auto">
                        <Form.Select
                          aria-label="carPlate"
                          name="carPlate"
                          value={carPlate}
                          onChange={onCarPlateChange}
                          required
                        >
                          <option>Επιλογή Οχήματος</option>
                          {cars}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* Right side with the print button */}
                  <div className="mt-2 mt-md-0">
                    <button className="secondary-btn isDisabled" type="button">
                      <FontAwesomeIcon icon={faPrint} /> Εκτύπωση
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>





        <Row className="mt-2">
          <Col sm={12} className="mt-2">
            <div className="content-wrapper">
              <div className="content-wrapper-header">
                Καταχωρημένα Καύσιμα Οχήματος {carPlate}
              </div>
              <div className="content-data pt-4">
                <Table responsive="sm" hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ημερομηνία</th>
                      <th>Όχημα</th>
                      <th>Χιλιόμετρα</th>
                      <th>Λίτρα</th>
                      <th>Τιμή / Λίτρο</th>
                      <th>Ποσό Πληρωμής</th>
                      <th>Οδηγός</th>
                      <th>Full</th>
                      <th>Κατανάλωση</th>
                      <th>Επεξεργασία</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableContent}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <button className="secondary-btn mt-3" onClick={prevPage} disabled={currentPage === 1}>
            Προηγούμενο
          </button>
          <span> {currentPage} από {totalPages} </span>
          <button className="secondary-btn mt-3" onClick={nextPage} disabled={currentPage === totalPages}>
            Επόμενο
          </button>
        </div>
        {showEditModal && (
          <EditFuel
            fuelId={selectedFuelId}
            show={showEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showNewModal && (
          <NewFuel
            show={showNewModal}
            handleClose={handleNewModalClose}
          />
        )}
      </Container>
    )
  }

  return content
}

export default FuelsList