const useFuelConsumption = (fuels) => {

    // Convert entities object into an array of fuels
    const allFuels = fuels.ids.map(id => fuels.entities[id]);

    // Ensure fuels are sorted by creation date
    const sortedFuels = allFuels.sort((a, b) => new Date(a.fuelDate) - new Date(b.fuelDate));

    const consumptionByFuelId = {};
    let isFirst = false;
    let firstFullKm = 0
    let totalLitersForSegment = 0;

    sortedFuels.forEach((fuel, index) => {
        // Once we find a full tank, start the process
        if (fuel.gasFull) {
            if (!isFirst) {
                isFirst = true
                firstFullKm = fuel.kilometers
                totalLitersForSegment = 0
            } else {
                totalLitersForSegment += fuel.gasLiters
                let totalKm = fuel.kilometers - firstFullKm
                let consumption = (totalLitersForSegment / totalKm) * 100
                consumptionByFuelId[fuel.id] = parseFloat(consumption.toFixed(1));
                firstFullKm = fuel.kilometers
                totalLitersForSegment = 0

            }
        } else {
            totalLitersForSegment += fuel.gasLiters;
        }
    });
    return consumptionByFuelId;
};

export default useFuelConsumption;