import { useEffect, useState } from "react";
import { useGetUsersQuery } from "./userApiSlice"

const UserList = () => {

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList')

  const [usernames, setUsernames] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      // Extract usernames while filtering out 'test'
      const filteredUsernames = users.ids
        .map(user => users.entities[user].username)
        .filter(username => username !== 'test');

      setUsernames(filteredUsernames);
    }
  }, [isSuccess, users]);

  console.log(usernames)

  return (
    <>

    </>
  )
}

export default UserList