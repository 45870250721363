export const CARS = [
    {carModel: "VW CANDY", carPlate: "ΕΡΙ 2176", region: "ΘΕΣΣΑΛΟΝΙΚΗ"},
    {carModel: "VW CANDY", carPlate: "ΕΡΙ 3034", region: "ΚΑΤΕΡΙΝΗ"},
    {carModel: "VW CANDY", carPlate: "ΕΡΙ 4037", region: "ΣΕΡΡΕΣ"},
    {carModel: "FIAT FIORINO", carPlate: "ΕΡΝ 5476", region: "ΚΡΗΤΗ"},
    {carModel: "PEUGEOT BIPPER", carPlate: "ΕΡΧ 4082", region: "ΑΘΗΝΑ"},
    {carModel: "FIAT DOBLO", carPlate: "ΕΡΧ 6752", region: "ΘΕΣΣΑΛΟΝΙΚΗ"},
    {carModel: "FORD TRANSIT", carPlate: "ΝΙΟ 3451", region: "ΣΕΡΡΕΣ / ΣΥΝΕΡΓΕΙΟ"},
    {carModel: "OPEL COMBO", carPlate: "ΝΚΗ 3532", region: "ΘΕΣΣΑΛΟΝΙΚΗ / ΛΙΑΝΙΚΗ"},
    {carModel: "CITROEN C3", carPlate: "CITROEN C3", region: "ΘΕΣΣΑΛΟΝΙΚΗ / ΛΙΑΝΙΚΗ"}
]