import React, { useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { useGetFuelQuery, useUpdateFuelMutation } from './fuelsApiSlice'
import FuelForm from './FuelForm'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const EditFuel = ({ fuelId, show, handleClose }) => {
    const { username } = useAuth()
    const navigate = useNavigate()

    // Fetch the specific fuel using the id
    const { data: fuel, isLoading, isError, erro } = useGetFuelQuery(fuelId, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [updateFuel, {
        isSuccess,
    }] = useUpdateFuelMutation()

    useEffect(() => {
        if (isSuccess) {
            handleClose(); // Close the modal
            window.focus() // Refocus the window after the modal closes
        }
    }, [isSuccess, handleClose])

    if (isLoading) return <p>Loading...</p>;
    if (isError || !fuel) return <p>Error loading fuel data.</p>;

    const onUpdateFuel = async (fuelData) => {
        await updateFuel(fuelData)
    }

    if (fuel && (fuel.userID?.username === username || username === 'g.varsamis')) {
        return (
            <>
                <Modal show={show} onHide={handleClose} centered size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title className="card-header">Προσθήκη Καυσίμου</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FuelForm onSubmit={onUpdateFuel} initialFuelFormData={fuel} handleClose={handleClose}/>
                    </Modal.Body>
                </Modal >
            </>
        )
    } else {
        return (<div>Δεν έχετε δικαίωμα επεξεργασίας αυτής της καταχώρησης.</div>)
    }
}

export default EditFuel