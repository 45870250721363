import React from 'react'
import { useGetUsersQuery } from './userApiSlice'
import EditUserForm from './EditUserForm'
import useAuth from '../../hooks/useAuth'

const EditUser = () => {
    const { id } = useAuth()
    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[id]
        }),
    })

    if (!user) return "No User"

  return <EditUserForm user={user} />
}

export default EditUser