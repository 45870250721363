import { store } from '../../app/store'
import { coffeeStoresApiSlice } from '../coffeeStores/storesApiSlice'
import { fuelsApiSlice } from '../fuels/fuelsApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(coffeeStoresApiSlice.util.prefetch('getCoffeeStores', 'storesList', { force: true }))
        store.dispatch(fuelsApiSlice.util.prefetch('getFuels', 'fuelsList', { force: true }))
    }, [])

    return <Outlet />
}

export default Prefetch