import React, { useState } from 'react'
import { useAddNewUserMutation } from './userApiSlice'

const AddUser = () => {

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)

    const onSaveUserClicked = async (e) => {
        e.preventDefault()

        await addNewUser({ username, password })

    }
    return (
        <form className="form" onSubmit={onSaveUserClicked}>

            <div className='form-group'>
                <label htmlFor='username'>Όνομα Χρήστη</label>
                <input
                    type='text'
                    id='username'
                    name='username'
                    value={username}
                    onChange={onUsernameChanged}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="password">Νέος Κωδικός</label>
                <input
                    type='password'
                    id="password"
                    name="password"
                    value={password}
                    onChange={onPasswordChanged}
                    autoComplete='new-password'
                    required
                />
            </div>

            <button>Αποθήκευση</button>

        </form>
    )
}

export default AddUser