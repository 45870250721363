import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useAddVisitMutation } from '../storesApiSlice'

const VisitForm = ({ id, storeName }) => {

    const [visitForm, setVisitForm] = useState({ visitDate: '' })
    const { username } = useAuth()

    const [addVisit, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddVisitMutation()

    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target

        const updatedFormData = { ...visitForm }

        updatedFormData[name] = value

        setVisitForm(updatedFormData)
    }

    useEffect(() => {
        if (isSuccess) {
            setVisitForm({ visitDate: '' })
            navigate(`/dashboard/coffeestores/` , { replace: true })
        }
    }, [isSuccess, navigate])

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleOpen = () => {
        setShow(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShow(false)
        try {
            const payload = { ...visitForm, username, id }
            await addVisit(payload)
            window.location.reload();  // Force a page refresh

        } catch (err) {
            console.log(err)
        }
    }

    if (isError) {
        return <p>{error?.data?.message}</p>
    }


    return (
        <>
            <Button className="secondary-btn w-100" onClick={handleOpen}>
                <FontAwesomeIcon icon={faPlus} /> Προσθήκη
            </Button>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="card-header">Προσθήκη Επικοινωνίας στο Κατάστημα: {storeName}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>

                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="visitDate">
                            <Form.Label>Ημερομηνία Επίσκεψης</Form.Label>
                            <Form.Control type="date" name='visitDate' value={visitForm.visitDate} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="visitType">
                            <Form.Label>Τύπος Επικοινωνίας</Form.Label>
                            <Form.Select aria-label="visitType" name='visitType' value={visitForm.visitType} onChange={handleChange}>
                                <option key='' value=''>Επιλεξτε Τύπο Επικοινωνίας</option>
                                <option key='ΤΗΛΕΦΩΝΙΚΗ' value='ΤΗΛΕΦΩΝΙΚΗ'>Τηλεφωνική</option>
                                <option key='ΕΠΙΣΚΕΨΗ' value='ΕΠΙΣΚΕΨΗ'>Επίσκεψη</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="comments">
                            <Form.Label>Σχόλια</Form.Label>
                            <Form.Control as="textarea" rows={4} name='comments' value={visitForm.comments} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='button' className='cancel-btn' onClick={handleClose}>
                            Άκυρο
                        </Button>
                        <Button type='submit' className='primary-btn'>
                            Αποθήκευση
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal >
        </>
    )
}

export default VisitForm