import { useAddNewFuelMutation } from "./fuelsApiSlice"
import { CARS } from "../../config/cars"
import { Modal } from 'react-bootstrap'
import FuelForm from "./FuelForm"
import { useEffect, useState } from "react"
import useAuth from "../../hooks/useAuth"

const VALID_NUM_REGEX = /^(0|([1-9][0-9]?|1[01][0-9]|120))(?:\.[0-9]+)?$/

const NewFuel = ({ show, handleClose }) => {

    const [mode, setMode] = useState('addNew')
    const { username: user } = useAuth()
    const initialFuelForm = {carPlate: '', fuelDate: '', gasLiters: '', kilometers: '', gasPrice: '', moneyAmount: '', gasFull: false}

    const [addNewFuel, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewFuelMutation();

    const onAddNewFuelClick = async (fuelData) => {
        try {
            const payload = { ...fuelData, user }; // adding user to the data
            await addNewFuel(payload).unwrap();
        } catch (error) {
            const errorMessage = error.data?.message || 'An unexpected error occurred';
        }
    }

    useEffect(() => {
        if (isSuccess) {
            handleClose(); // Close the modal
            window.focus() // Refocus the window after the modal closes
        }
    }, [isSuccess, handleClose])

    let content

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }


    content = (
        <>
            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="card-header">Προσθήκη Καυσίμου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FuelForm onSubmit={onAddNewFuelClick} initialFuelFormData={initialFuelForm} handleClose={handleClose} mode={mode} />
                </Modal.Body>
            </Modal >
        </>
    )

    return content
}

export default NewFuel