import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const checklistsAdapter = createEntityAdapter({})
const initialState = checklistsAdapter.getInitialState()

export const checklistsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChecklists: builder.query({
            query: () => '/checklists',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedChecklists = responseData.map(checklist => {
                    checklist.id = checklist._id
                    return checklist
                })
                return checklistsAdapter.setAll(initialState, loadedChecklists)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'Checklists', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Checklist', id}))
                    ]
                } else return ({ type: 'Checklists', id: 'LIST' })
            }
        }),
        addNewChecklist: builder.mutation({
            query: initialChecklistData => ({
                url: '/checklists',
                method: 'POST',
                body: {
                    ...initialChecklistData
                },
                invalidatesTags: [
                    { type: 'Checklists', id: 'LIST' }
                ]
            })
        }),
        generatePdf: builder.mutation({
            query: id => ({
                url: '/checklists/generate',
                method: 'POST',
                body: { id },
                responseHandler: (response) => response.blob()
            })
        })
    })
})

export const {
    useGetChecklistsQuery,
    useAddNewChecklistMutation,
    useGeneratePdfMutation
} = checklistsApiSlice

// Returns the query result object
export const selectChecklistsResult = checklistsApiSlice.endpoints.getChecklists.select();

// Creates memoized selector
const selectChecklistsData = createSelector(
    selectChecklistsResult,
    checklistResult => checklistResult.data // normalized state object with ids & entities
)

// getSelectors createsthese selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllChecklists,
    selectById: selectChecklistById,
    selectIds: selectChecklistIds
    // Pass in a selector that returs the users slice of state
} = checklistsAdapter.getSelectors(state => selectChecklistsData(state) ?? initialState)