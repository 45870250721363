import React, { useEffect, useState } from 'react'
import { useAddTaskMutation } from './projectsApiSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


const AddTask = ({project}) => {

    const [addTask, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddTaskMutation()

    const navigate = useNavigate()

    const [show, setShow] = useState(false)
    const [taskForm, setTaskForm] = useState({ taskName: '', taskStatus: "Δεν Ξεκινησε", taskAssignee: [], taskStartDate: '' })

    const handleChange = e => {
        const { name, value } = e.target

        const updatedFormData = { ...taskForm }

        // If the field is "assignee", handle multiple values separated by commas
        if (name === "taskAssignee") {
            // Split the value by commas and trim any excess whitespace
            updatedFormData[name] = value.split(',').map(item => item.trim());
        } else {
            updatedFormData[name] = value;
        }


        setTaskForm(updatedFormData)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleOpen = () => {
        setShow(true)
    }

    useEffect(() => {
        if (isSuccess) {
            setTaskForm({ taskName: '', taskStatus: "Δεν Ξεκινησε", taskAssignee: [], taskStartDate: '' })
            navigate(`/dashboard/projects/${project}`)
        }
    }, [isSuccess, navigate])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShow(false)
        try {
            const payload = { ...taskForm, project }
            await addTask(payload)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <Button className='primary-btn' type="button" onClick={handleOpen}>
                <FontAwesomeIcon icon={faPlus} /> Προσθήκη Εργασίας
            </Button>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="card-header">Προσθήκη Εργασίας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-3' controlId='taskName'>
                            <Form.Label>Όνομα Εργασίας</Form.Label>
                            <Form.Control type="text" autoComplete="off" name='taskName' value={taskForm.taskName} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskStatus">
                            <Form.Label>Κατάσταση</Form.Label>
                            <Form.Select name='taskStatus' value={taskForm.taskStatus} onChange={handleChange} placeholder="Κατάσταση" required>
                                <option key='notStarted' value='Δεν ξεκίνησε'>Δεν Ξεκίνησε</option>
                                <option key='inProgress' value='Σε εξέλιξη'>Σε εξέλιξη</option>
                                <option key='done' value='Ολοκληρώθηκε'>Ολοκληρώθηκε</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskAssignee">
                            <Form.Label>Μέλη(*)</Form.Label>
                            <Form.Control
                                list="assigneeOptions"
                                name="taskAssignee"
                                value={taskForm.taskAssignee}
                                onChange={handleChange}
                                placeholder=""
                            />
                            <datalist id="assigneeOptions">
                                <option value="g.varsamis" />
                                <option value="p.matziounis" />
                            </datalist>
                            <Form.Text className="text-muted">
                                Διαχωρείστε τα μέλη με κόμμα (,)
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskStartDate">
                            <Form.Label>Ημερομηνία Αναπλήρωσης(*)</Form.Label>
                            <Form.Control type="date" name='taskStartDate' value={taskForm.taskStartDate} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <button type="submit" title="Save" className="save-btn">
                                <FontAwesomeIcon icon={faFloppyDisk} /> Αποθήκευση
                            </button>
                            <button type="button" onClick={handleClose} className="cancel-btn">
                                <FontAwesomeIcon icon={faCancel} /> Ακύρωση
                            </button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default AddTask