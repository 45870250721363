import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetprojectsQuery, useUpdateTaskStatusMutation } from './projectsApiSlice'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import AddTask from './AddTask'
import '../../assets/css/table.css'

const ProjectView = () => {

    const { id } = useParams()

    const { projectItem } = useGetprojectsQuery('scheduleList', {
        selectFromResult: ({ data }) => ({
            projectItem: data?.entities[id]
        })
    })

    const [updateTaskStatus, {
        isSuccess
    }] = useUpdateTaskStatusMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            navigate(`/dashboard/projects/${id}`)
        }
    },[isSuccess, navigate])
    
    const handleStatusChange = async (e, taskId) => {
        try {
            const taskStatus = e.target.value
            const payload = { taskStatus, taskId, id}
            await updateTaskStatus(payload)
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <Container className='overflow-hidden'>
                <Row className='mt-2'>
                    <Col sm={12} className='mt-2'>
                        <div className='content-wrapper'>
                            <div className='content-wrapper-header'>
                                <h4>{projectItem?.projectName}</h4>
                            </div>
                            <div className='content-data pt-4'>
                                <p>
                                    Κατάσταση: {projectItem?.status}
                                </p>
                                <p>
                                    Μέλη:{projectItem?.assignee && projectItem?.assignee.length > 0
                                        ? projectItem?.assignee.map((user, idx) => (
                                            <span key={idx}>
                                                {user.username}
                                                {idx < projectItem?.assignee.length - 1 ? ', ' : ''}
                                            </span>
                                        ))
                                        : 'No Assignees'}
                                </p>
                                <p>
                                    Ημερομηνία Έναρξης: {new Date(projectItem?.startDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}
                                </p>
                                <p>
                                    Καταληκτική Ημερομηνία: {projectItem?.dueDate ? new Date(projectItem?.dueDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' }) : "-"}
                                </p>
                                <p>
                                    Προτεραιότητα: {projectItem?.priority}
                                </p>
                                <p>
                                    Κατηγορία: {projectItem?.category}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='mt-2'>
                        <div className='content-wrapper'>
                            <AddTask project={id} />
                            <div className='content-wrapper-header'>
                                <h4>Εργασίες</h4>
                            </div>

                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th className="col">Εργασία</th>
                                        <th className="col">Κατάσταση</th>
                                        <th className="col">Μέλη</th>
                                        <th className="col">Ημερομηνία Έναρξης</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        projectItem?.tasks?.map((task) =>
                                            <tr key={task._id}>
                                                <td>{task.taskName}</td>
                                                <td>
                                                    <Form.Group className="mb-3" controlId="taskStatus">
                                                        <Form.Select name='taskStatus' value={task.taskStatus} onChange={(e) => handleStatusChange(e, task._id)} required>
                                                            <option key='notStarted' value='Δεν ξεκίνησε'>Δεν Ξεκίνησε</option>
                                                            <option key='inProgress' value='Σε εξέλιξη'>Σε εξέλιξη</option>
                                                            <option key='done' value='Ολοκληρώθηκε'>Ολοκληρώθηκε</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    {
                                                        task.taskAssignee && task.taskAssignee.length > 0
                                                            ? task.taskAssignee.map((user, idx) => (
                                                                <span key={idx}>
                                                                    {user.username}
                                                                    {idx < task.taskAssignee.length - 1 ? ', ' : ''}
                                                                </span>
                                                            ))
                                                            : 'No Assignees'
                                                    }
                                                </td>
                                                <td>{new Date(task.taskStartDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            </tr>

                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProjectView