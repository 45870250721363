import { Outlet } from 'react-router-dom';
import Header from './Header';


const Dashboard = () => {
  return (
    <>
      <div id='layout-wrapper'>
        <Header />
        <div className='main-content pb-4'>
          <Outlet />
        </div>
      </div>
    </>

  );
}

export default Dashboard