import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './features/authentication/Login';
import Dashboard from './components/Dashboard';
import Index from './features/authentication/Index';
import FuelsList from './features/fuels/FuelsList';
import CoffeeStoresList from './features/coffeeStores/CoffeeStoresList';
import NewStoreForm from './features/coffeeStores/NewStoreForm';
import Prefetch from './features/authentication/Prefetch';
import PersistLogin from './features/authentication/PersistLogin';
import EditUser from './features/users/EditUser';
import AddUser from './features/users/AddUser';
import UserList from './features/users/UserList';
import EditStore from './features/coffeeStores/EditStore';
import EditFuel from './features/fuels/EditFuel';
import AddToProjects from './features/schedule/AddToProjects';
import Updates from './features/updates/Updates';
import Overview from './features/overview/Overview';
import ChecklistList from './features/checklists/ChecklistList';
import ChecklistForm from './features/checklists/ChecklistForm';
import ChecklistForm2 from './features/checklists/ChecklistForm2';
import NewFuel from './features/fuels/NewFuel';
import ProjectsList from './features/schedule/ProjectsList';
import ProjectView from './features/schedule/ProjectView';
import UserScheduleList from './features/userSchedule.js/UserScheduleList';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Login />} />
        <Route path='login' element={<Login />} />


        <Route element={<PersistLogin />}>
          <Route element={<Prefetch />}>

            <Route path='dashboard' element={<Dashboard />}>
              <Route index element={<Overview />} />

              <Route path='overview'>
                <Route index element={<Overview />} />
              </Route>

              <Route path='fuels'>
                <Route index element={<FuelsList />} />
                <Route path='new' element={<NewFuel />} />
                <Route path='edit/:id' element={<EditFuel />} />
              </Route>

              <Route path='coffeestores/'>
                <Route index element={<CoffeeStoresList />} />
                <Route path='new' element={<NewStoreForm />} />
                <Route path='edit/:id' element={<EditStore />} />
              </Route>

              <Route path='projects'>
                <Route index element={<ProjectsList />} />
                <Route path='add/' element={<AddToProjects />} />
                <Route path=':id' element={<ProjectView />} />
              </Route>

              <Route path='checklists'>
                <Route index element={<ChecklistList />} />
                <Route path='add' element={<ChecklistForm />} />
                <Route path='form' element={<ChecklistForm2 />}/>
              </Route>

              <Route path='user_schedule'>
                <Route index element={<UserScheduleList />} />
              </Route>

              <Route path='user'>
                <Route index element={<UserList />} />
                <Route path='edit' element={<EditUser />} />
                <Route path='add' element={<AddUser />} />
              </Route>

              <Route path='updates' element={<Updates />} />

            </Route>

          </Route>

        </Route>
      </Route>
    </Routes>
  );
}

export default App;
