import React, { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, Modal } from 'react-bootstrap'
import { useAddSamplingMutation } from '../storesApiSlice'

const SamplingForm = ({ id, storeName }) => {
    const [samplingForm, setSamplingForm] = useState({ samplingDate: '', coffee: '' })
    const [show, setShow] = useState(false)

    const { username } = useAuth()
    const navigate = useNavigate()

    const [addSampling, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddSamplingMutation()

    const handleChange = (e) => {
        const { name, value } = e.target

        const updatedFormData = { ...samplingForm }

        updatedFormData[name] = value

        setSamplingForm(updatedFormData)
    }

    useEffect(() => {
        if (isSuccess) {
            setSamplingForm({ samplingDate: '', coffee: '' })
            navigate(`/dashboard/coffeestores/` , { replace: true })
        }
    }, [isSuccess, navigate])


    const handleClose = () => {
        setShow(false)
    }

    const handleOpen = () => {
        setShow(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShow(false)
        try {
            const payload = { ...samplingForm, username, id }
            await addSampling(payload)
            window.location.reload();  // Force a page refresh

        } catch (err) {
            console.log(err)
        }
    }

    if (isError) {
        return <p>{error?.data?.message}</p>
    }

    return (
        <>
            <Button className="secondary-btn w-100" onClick={handleOpen}>
                <FontAwesomeIcon icon={faPlus} /> Προσθήκη Δειγματισμού
            </Button>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="card-header">Προσθήκη Επικοινωνίας στο Κατάστημα: {storeName}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="samplingDate">
                            <Form.Label>Ημερομηνία Δειγματισμού</Form.Label>
                            <Form.Control type="date" name='samplingDate' value={samplingForm.samplingDate} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="coffee">
                            <Form.Label>Προιόντα Δειγματισμού</Form.Label>
                            <Form.Control type="text" name='coffee' value={samplingForm.coffee} onChange={handleChange} />
                            <Form.Text className="text-muted">
                                Χρησιμοποιήστε κόμμα(,) για να διαχωρίσετε τα προϊόντα.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="comments">
                            <Form.Label>Σχόλια Δειγματισμού</Form.Label>
                            <Form.Control as="textarea" rows={4} name='comments' value={samplingForm.comments} onChange={handleChange} autoComplete="off" />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='cancel-btn' onClick={handleClose}>
                            Άκυρο
                        </button>
                        <button type='submit' className='primary-btn'>
                            Αποθήκευση
                        </button>
                    </Modal.Footer>
                </Form >
            </Modal >
        </>
    )
}

export default SamplingForm