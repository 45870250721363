import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Table, Button } from 'react-bootstrap'
import { useGeneratePdfMutation, useGetChecklistsQuery } from './checklistsApiSlice'

const ChecklistList = () => {
    const navigate = useNavigate()

    const {
        data: checklists,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChecklistsQuery('checklist', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [generatePdf, {
        isLoading: pdfLoading
    }] = useGeneratePdfMutation()

    const handleGenerate = async (e, id) => {
        e.preventDefault()
        try {
            // Trigger the mutation with the ID
            const blob = await generatePdf( id ).unwrap();

            // Create a URL for the Blob and open it in a new tab
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            // Clean up the URL object
            window.URL.revokeObjectURL(url);
        } catch (err) {
            // Handle errors appropriately
            if (err.status === 404) {
                console.error('Error: PDF not found on server. Please check the URL and route on the backend.');
            } else if (err.status === 400) {
                console.error('Error: Invalid ID provided. Please ensure the ID is correct.');
            } else {
                console.error('Error generating PDF:', err);
            }
        }
    }

    const handleButton = () => navigate('/dashboard/checklists/add');

    let content

    if (isLoading) content = <p>Loading...</p>

    if (isError) content = <p>{error?.data?.message}</p>

    if (isSuccess) {
        const { ids, entities } = checklists

        content = (
            <Container className='overflow-hidden'>
                <div className='d-flex justify-content-between mt-4'>
                    <div><h2>Checklists</h2></div>

                    <Button className='primary-btn' type='button' onClick={handleButton}>
                        <FontAwesomeIcon icon={faPlus} /> Νέο Checklist
                    </Button>
                </div>

                {/* Data Containers */}
                <Row className='mt-2'>
                    <Col sm={12} className='mt-2'>
                        <div className='content-wrapper'>
                            <div className='content-data pt-4'>
                                <Table responsive='sm' hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ημερομηνία</th>
                                            <th>Κατάστημα</th>
                                            <th>Τεχνικός</th>
                                            <th>Ενέργειες</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ids.map((id, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{new Date(entities[id].date).toLocaleString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
                                                <td>{entities[id].name}</td>
                                                <td>{entities[id].technician?.username}</td>
                                                <td>
                                                    <button className='view-button' type='button' onClick={(e) => handleGenerate(e, id)}>
                                                        <FontAwesomeIcon icon={faEye} /> Προβολή
                                                    </button>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }


    return content
}

export default ChecklistList