import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { CARS } from "../../config/cars"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

const FuelForm = ({ initialFuelFormData, mode, onSubmit, handleClose }) => {
    const [fuelForm, setFuelForm] = useState(initialFuelFormData)

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFuelForm({
            ...fuelForm,
            [name]: type === 'checkbox' ? checked : value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(fuelForm);
    }

    const cars = CARS.map(car => {
        return (
            <option
                key={car.carPlate}
                value={car.carPlate}
            >{car.carPlate}</option>
        )
    })

    const formatDateForInput = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="carPlate">
                    <Form.Label>Επιλογή Οχήματος(*)</Form.Label>
                    <Form.Select aria-label="carPlate" name="carPlate" value={fuelForm.carPlate} onChange={handleChange} required>
                        <option>Επιλογή Οχήματος</option>
                        {cars}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="fuelDate">
                    <Form.Label>Ημερομηνία Αναπλήρωσης(*)</Form.Label>
                    <Form.Control type="date" name='fuelDate' value={formatDateForInput(fuelForm.fuelDate)} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasLiters'>
                    <Form.Label>Λίτρα(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasLiters' value={fuelForm.gasLiters} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='kilometers'>
                    <Form.Label>Χιλιόμετρα(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="1" name='kilometers' value={fuelForm.kilometers} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasPrice'>
                    <Form.Label>Τιμή Καυσίμου(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='gasPrice' value={fuelForm.gasPrice} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='moneyAmount'>
                    <Form.Label>Ποσό Πληρωμής(*)</Form.Label>
                    <Form.Control type="number" autoComplete="off" step="0.1" name='moneyAmount' value={fuelForm.moneyAmount} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className='mb-3' controlId='gasFull'>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="gasFull"
                        name="gasFull"
                        checked={fuelForm.gasFull}
                        onChange={handleChange}
                        label="Full"
                    />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <p><i>Τα πεδία με αστερίσκο (*) είναι υποχρεωτικά!</i></p>
                </Form.Group>
                <Form.Group className='mb-3'>
                    <button type="submit" title="Save" className="save-btn">
                        <FontAwesomeIcon icon={faFloppyDisk} /> {mode === 'addNew' ? 'Αποθήκευση' : 'Ενημέρωση'}
                    </button>
                    <button type="button" onClick={handleClose} className="cancel-btn">
                        <FontAwesomeIcon icon={faFloppyDisk} /> Ακύρωση
                    </button>
                </Form.Group>
            </Form>

        </>
    );
}

export default FuelForm;