import { useState, useEffect } from "react";

const usePersist = () => {
    // Determine initial persist state. Preference stored in localStorage takes precedence.
    // If not found in localStorage, check sessionStorage as a fallback.
    const [persist, setPersist] = useState(() => {
        const storedPersistLocal = localStorage.getItem("persist");
        if (storedPersistLocal !== null) {
            return JSON.parse(storedPersistLocal);
        }
        const storedPersistSession = sessionStorage.getItem("persist");
        return storedPersistSession !== null ? JSON.parse(storedPersistSession) : false;
    });

    useEffect(() => {
        if (persist) {
            // User chose to "Remember Me": store the preference in localStorage
            localStorage.setItem("persist", "true");
            sessionStorage.removeItem("persist"); // Clean up session storage
        } else {
            // User chose NOT to "Remember Me": store the flag in sessionStorage
            sessionStorage.setItem("persist", "true");
            localStorage.removeItem("persist"); // Ensure to clean up local storage
        }
    }, [persist]);

    return [persist, setPersist];
};

export default usePersist