import React from 'react'
import { Alert } from 'react-bootstrap'

const Updates = () => {
  return (
    <Alert variant="primary">
      <Alert.Heading>Ενημέρωση Εφαρμογής 24/04</Alert.Heading>
      <p>
        Έχει γίνει αναβάθμιση της εφαρμφογής με τις παρακάτω αλλαγές και προσθήκες.
      </p>
      <hr />
      <p className="mb-0">
        <ul>
          <li><b>Αλλαγές</b> στη διεπαφή ώστε να είναι πιο εύχρηση από κινητές συσκευές</li>
          <li><b>Προσθήκη</b> όλων των πεδίων καταχώρησης στην αρχική φόρμα. Πλέον μπορούμε να περνάμε όλες τις πληροφορίες του Καταστήματος
            καθώς και Επίσκεψη, Δειγματισμό, Εξοπλισμό και Εκδήλωση Ενδιαφέροντος από την αρχική καταχώρηση.
          </li>
          <li>
          <b>Προσθήκη</b> δυνατότητας επεξεργασίας στις καταχωρήσεις που έχουμε κάνει. Μέσα στη σελίδα του κάθε καταστήματος βρίσκεται το κουμπί "Επεξεργασία". Στη σελίδα καυσίμων το κουμπί επεξεργασίας βρίσκεται δίπλα σε κάθες καταχώρηση.
          </li>
          <li>
          <b>Προσθήκη</b> δυνατότητας διαχείρησης του προγράμματος μας. Μπορούμε πλέον να προγραμματίζουμε επισκέψεις, στησίματα και δειγματισμού μέσα από την εφαρμφογή.
          Επιλέγοντας ένα κατάστημα βρίσκουμε το κουμπί "Προγραμματισμός Επίσκεψεις" δίπλα στην "Επεξεργασία". Πατώντας το μας ανοίγει η φόρμα για να συμπληρώσουμε τα στοιχεία της επόμενης επίσκεψης.
          Στη σελίδα <b>Πρόγραμμα</b> βλέπουμε όλες τις καταχωρήσεις
          </li>
          <li>
            <b>Προσθήκη</b> δυνατότητας πολλαπλών επισκέψεων και δειγματισμών. Επίσης στην Προβολή του κάθε καταστήματος βλέπουμε όλες τις καταχωρήσεις από δειγματισμούς και επισκέψεις.
          </li>
          <li>
            <b>Αλλαγή</b> στη φόρμα της επίσκεψης. Πλέον μπορούμε να καταχωρήσουμε και τηλεφωνική Επικοινωνία.
          </li>
        </ul>
      </p>
      <hr />
      <ul>
        <li>Δεν λειτουργεί το κουμπί <b>"Ολοκληρώθηκε"</b> στο Πρόγραμμα. Θα διορθωθεί σύντομα</li>
        <li><b>Κάποιες Καταχωρήσεις ίσως να μην εμφανίζουν όλες τις πληροφορίες! Θα επανέλθουν σύντομα.</b></li>
      </ul>
    </Alert>
  )
}

export default Updates