import React from 'react'
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useGetprojectsQuery } from './projectsApiSlice'
import AddToProjects from './AddToProjects'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

const ProjectsList = () => {

    const { id, roles } = useAuth()

    const {
        data: schedule,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetprojectsQuery('scheduleList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content
    if (isLoading) content = <p>Loading...</p>

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    const navigate = useNavigate();

    const handleClick = (project) => {
        navigate(`/dashboard/projects/${project}`);
    }

    const showAlert = () => {
        alert('Δεν έχετε δικαιώματα για να εκτελέσετε αυτήν την ενέργεια')
    }

    if (isSuccess) {
        const { ids, entities } = schedule;
        // Filter projects based on the user's role and assignment
        const filteredProjects = ids.map((projectId) => {
            const project = entities[projectId]; // Get the project using the ID
            // Check if the user has the "manager" role
            if (Array.isArray(roles) && roles.includes("manager")) {
                return project; // Managers see all projects
            }
            // Check if the user's ID is in the assignee array
            return project && Array.isArray(project.assignee) && project.assignee.some(assignee => assignee._id === id)
                ? project
                : null; // Return the project or null
        }).filter(project => project !== null); // Remove null values

        const tableContentSchedule = filteredProjects.map((project, index) => (
            <tr key={project.id} style={{ cursor: 'pointer' }}>
                <td onClick={() => handleClick(project.id)}>{index + 1}</td>
                <td onClick={() => handleClick(project.id)}>{project.projectName}</td>
                <td onClick={() => handleClick(project.id)}>{project.status}</td>
                <td onClick={() => handleClick(project.id)}>
                    {project.assignee && project.assignee.length > 0
                        ? project.assignee.map((user, idx) => (
                            <span key={idx}>
                                {user.username}
                                {idx < project.assignee.length - 1 ? ', ' : ''}
                            </span>
                        ))
                        : 'No Assignees'}
                </td>
                <td onClick={() => handleClick(project.id)}>{new Date(project.startDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                <td onClick={() => handleClick(project.id)}>{project.dueDate ? new Date(project.dueDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' }) : "-"}</td>
                <td onClick={() => handleClick(project.id)}>{project.priority}</td>
                <td onClick={() => handleClick(project.id)}>{project.category}</td>
                <td>
                    <Button className='secondary-btn me-2' onClick={showAlert}>Επεξεργασία</Button>
                    <Button className='secondary-btn' onClick={showAlert}>Διαγραφή</Button>
                </td>
            </tr>
        ))

        content = (
            <>
                <Container className='overflow-hidden'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4>Projects & Tasks</h4>
                    </div>
                    {/* Start Of content helper buttons */}
                    <Row>
                        <Col lg={12}>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='d-flex align-items-center flex-wrap gap-2 mt-2 mb-2'>
                                        <div className='flex-grow-1'>
                                            <AddToProjects />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* End Of content helper buttons */}


                    <Row className='mt-2'>
                        <Col sm={12} className='mt-2'>
                            <div className='content-wrapper'>
                                <div className='content-wrapper-header'>
                                    Projects
                                </div>
                                <div className='content-data pt-4'>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th className="col">#</th>
                                                <th className="col">Όνομα Εργασίας</th>
                                                <th className="col">Κατάσταση</th>
                                                <th className="col">Μέλη</th>
                                                <th className="col">Ημερομηνία Έναρξης</th>
                                                <th className="col">Καταληκτική Ημερομηνία</th>
                                                <th className="col">Προτεραιότητα</th>
                                                <th className="col">Κατηγορία</th>
                                                <th className="col">Ενέργειες</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableContentSchedule}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    } else {
        content = (
            <>
                <Container className='overflow-hidden'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4>Projects & Tasks</h4>
                    </div>
                    {/* Start Of content helper buttons */}
                    <Row>
                        <Col lg={12}>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='d-flex align-items-center flex-wrap gap-2 mt-2 mb-2'>
                                        <div className='flex-grow-1'>
                                            <AddToProjects />
                                        </div>
                                        <div className='flex-shrink-0'>
                                            <button className='plus-button' type="button" disabled>
                                                <FontAwesomeIcon icon={faFilter} /> Φίλτρα
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* End Of content helper buttons */}
                </Container>
            </>
        )
    }
    return content
}

export default ProjectsList