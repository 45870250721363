import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const userScheduleAdapter = createEntityAdapter({})
const initialState = userScheduleAdapter.getInitialState()

export const userScheduleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSchedule: builder.query({
            query: ({ user, date }) =>
                `/user-schedule?user=${user}&date=${date}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedUserSchedule = responseData.map(schedule => {
                    schedule.id = schedule._id
                    return schedule
                })
                return userScheduleAdapter.setAll(initialState, loadedUserSchedule)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        {type: 'userSchedule', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'userSchedule', id }))
                    ]
                } else return [{ type:' userSchedule', id: 'LIST' }]
            }
        }),
        addToUserSchedule: builder.mutation({
            query: initialScheduleData => ({
                url: '/user-schedule',
                method: 'POST',
                body: {
                    ...initialScheduleData,
                }
            }),
            invalidatesTags: [
                {type: 'userSchedule', id: 'LIST'}
            ]
        })
    })
})

export const {
    useGetUserScheduleQuery,
    useAddToUserScheduleMutation
} = userScheduleApiSlice

export const selectUserScheduleResult = userScheduleApiSlice.endpoints.getUserSchedule.select()

const selectUserScheduleData = createSelector(
    selectUserScheduleResult,
    userScheduleResult => userScheduleResult.data
)