import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useAddNewChecklistMutation } from './checklistsApiSlice'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const initialFormData = {
    espressoMachines: [
        {
            group: [
                {

                },
                // More groups as needed
            ],
        },
        // More espresso machines as needed
    ],
};

const ChecklistForm = () => {
    const FORM_FIELDS = [
        { type: 'text', name: 'name', label: 'Όνομα Πελάτη', required: true },
        { type: 'text', name: 'address', label: 'Διευθυνση Πελάτη', required: true },
        { type: 'date', name: 'date', label: 'Ημερομηνία', required: true },
        { type: 'time', name: 'time', label: 'Ώρα', required: true },
        { type: 'technician', name: 'technician', label: 'Τεχνικός', required: true }
    ]

    const FORM_FIELDS_ESPRESSO_MACHINE = [
        { type: 'text', name: 'model', label: 'Μοντέλο', required: true },
        { type: 'text', name: 'serialNo', label: 'Serial Number', required: true },
    ]

    const FORM_FIELDS_GRINDER = [
        { type: 'text', name: 'grinderSerialNo', label: 'Serial Number', required: true },
    ]

    const FORM_FIELDS_ESPRESSO_MACHINE_TECHNICAL = [
        { type: 'number', name: 'pumpPressure', label: 'Πίεση Αντλίας', required: false },
        { type: 'number', name: 'standByPressure', label: 'Πίεση Αναμονής', required: false },
        { type: 'number', name: 'boilerPressure', label: 'Πίεση Boiler', required: false },
    ]

    const FORM_FIELDS_ESPRESSO_MACHINE_GROUP = [
        { type: 'number', name: 'flowRate', label: 'Flow Rate', required: false },
        { type: 'number', name: 'temperature', label: 'Θερμοκρασία', required: false },
        { type: 'number', name: 'but1Total', label: 'BUTTON 1 TOTAL', required: false },
        { type: 'number', name: 'but2Total', label: 'BUTTON 2 TOTAL', required: false },
        { type: 'number', name: 'but3Total', label: 'BUTTON 3 TOTAL', required: false },
        { type: 'number', name: 'but4Total', label: 'BUTTON 4 TOTAL', required: false },
    ]

    const FORM_FIELDS_GRINDER_CHECK = [
        { type: 'number', name: 'doses', label: 'DOSES ', required: false },
        { type: 'text', name: 'coffee', label: 'COFFEE', required: false },
        { type: 'number', name: 'grindSet', label: 'GRIND SET', required: false },
        { type: 'number', name: 'doseTime', label: 'DOSE TIME', required: false },
        { type: 'number', name: 'doseIn', label: 'DOSE IN', required: false },
        { type: 'number', name: 'out', label: 'OUT(coffee)', required: false },
        { type: 'number', name: 'outTime', label: 'TIME', required: false },
    ]

    const FORM_FIELDS_GRINDER_CORRECTION = [
        { type: 'text', name: 'corCoffee', label: 'COFFEE', required: false },
        { type: 'number', name: 'corGrindSet', label: 'GRIND SET', required: false },
        { type: 'number', name: 'corDoseTime', label: 'DOSE TIME', required: false },
        { type: 'number', name: 'corDoseIn', label: 'DOSE IN', required: false },
        { type: 'number', name: 'corOut', label: 'OUT(coffee)', required: false },
        { type: 'number', name: 'corOutTime', label: 'TIME', required: false },
    ]

    const FORM_FIELDS_RECIPES_GROUP = [
        { type: 'number', name: 'button2', label: 'BUTTON 2', required: false },
        { type: 'number', name: 'button3', label: 'BUTTON 3', required: false },
        { type: 'number', name: 'button4', label: 'BUTTON 4', required: false },
    ]

    const FORM_FIELDS_FILTRATION = [
        { type: 'date', name: 'datePlacement', label: 'PLACEMENT DATE', required: false },
        { type: 'number', name: 'initPPM', label: 'INITIAL PPM', required: false },
        { type: 'number', name: 'corrPPM', label: 'CORRECTION PPM', required: false },
        { type: 'number', name: 'remLitres', label: 'REMAINING LITRES', required: false },
        { type: 'number', name: 'byPass', label: 'BY PASS No.', required: false },
    ]

    const [addNewChecklist, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewChecklistMutation()

    const navigate = useNavigate()
    const { username } = useAuth()

    const [formData, setFormData] = useState(initialFormData);
    const [groupIndex, setGroupIndex] = useState(0);

    formData.technician = username

    useEffect(() => {
        if (isSuccess) {
            setFormData({})
            navigate('/dashboard/checklists')
        }
    }, [isSuccess, navigate])

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleEspressoMachineChange = (e, espressoMachineIndex) => {
        const {name, value } = e.target
        const updatedFormData = { ...formData }

        if (!updatedFormData.espressoMachines[espressoMachineIndex]) {
            updatedFormData.espressoMachines[espressoMachineIndex] = []
        }

        updatedFormData.espressoMachines[espressoMachineIndex][name] = value;

        setFormData(updatedFormData)
    }

    const handleGroupChange = (e, espressoMachineIndex, groupIndex) => {
        const { name, value } = e.target;
      
        // Clone the formData to avoid mutating state directly
        const updatedFormData = { ...formData };
      
        // Ensure the espressoMachines array exists
        if (!updatedFormData.espressoMachines[espressoMachineIndex]) {
          updatedFormData.espressoMachines[espressoMachineIndex] = { group: [] };
        }
      
        // Ensure the group array exists for the given espressoMachineIndex
        if (!updatedFormData.espressoMachines[espressoMachineIndex].group) {
          updatedFormData.espressoMachines[espressoMachineIndex].group = [];
        }
      
        // Ensure the specific group exists at groupIndex
        if (!updatedFormData.espressoMachines[espressoMachineIndex].group[groupIndex]) {
          updatedFormData.espressoMachines[espressoMachineIndex].group[groupIndex] = {};
        }
      
        // Update only the specific field's value for this group
        updatedFormData.espressoMachines[espressoMachineIndex].group[groupIndex][name] = value;
      
        // Set the updated formData
        setFormData(updatedFormData);
      };      
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addNewChecklist(formData)
        } catch (e) {
            const errorMessage = e.data?.message || 'Κάτι δεν πήγε καλά'
            console.log(errorMessage)
        }
    };


    let content
    let espressoMachineIndex = 0

    content = (
        <Container className='overflow-hidden'>
            <div className='d-flex justify-content-between mt-4'>
                <div><h2>Προσθήκη Checklist</h2></div>
            </div>
            <Row className='mt-2'>
                <Col className='mt-2' sm={12}>
                    <div className='content-wrapper'>

                        <div className='content-data pt-4'>

                            <div className='content-wrapper-header bg-info'>
                                Στοιχεία Πελάτη
                            </div>
                            <Form onSubmit={handleSubmit}>
                                {FORM_FIELDS.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange}/>
                                    </Form.Group>
                                ))}

                                <Form.Group as={Col} controlId='type'>
                                    <Form.Label>Τύπος</Form.Label>
                                    <Form.Select aria-label="type" name="type" value={formData['type']} onChange={handleChange} required>
                                        <option value="KOLEKTIVA">KOLEKTIVA</option>
                                        <option value="SINGLE ROAST">SINGLE ROAST</option>
                                        <option value="ESPRESSONIST">ESPRESSONIST</option>
                                    </Form.Select>
                                </Form.Group>

                                <div className='content-wrapper-header'>
                                    Μηχανές Καφέ
                                </div>

                                {FORM_FIELDS_ESPRESSO_MACHINE.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData.espressoMachines[espressoMachineIndex][field.name] ?? ''} onChange={(e) => handleEspressoMachineChange(e, espressoMachineIndex)}  />
                                    </Form.Group>
                                ))}

                                <div className='content-wrapper-header'>
                                    Τεχνικός Έλεγχος Μηχανής
                                </div>

                                {FORM_FIELDS_ESPRESSO_MACHINE_TECHNICAL.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData.espressoMachines[espressoMachineIndex][field.name] ?? ''} onChange={(e) => handleEspressoMachineChange(e, espressoMachineIndex)} />
                                    </Form.Group>
                                ))}

                                <div className='content-wrapper-header'>
                                    Steamwands
                                </div>
                                <Form.Group as={Col}>
                                    <Form.Label>Steamwand Αριστερα ΚΑΘΑΡΙΟΤΗΤΑ</Form.Label>
                                    <ToggleButtonGroup type='radio' name='steamWandLeftClean'>
                                        <ToggleButton variant='outline-primary' value={'Ναι'} >Ναί</ToggleButton>
                                        <ToggleButton variant='outline-primary' value={'Όχι'}  >Όχι</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Steamwand Αριστερα Σωστή Λειτουργία</Form.Label>
                                    <ToggleButtonGroup type='radio' name='steamWandLeftOperational'>
                                        <ToggleButton variant='outline-primary' value={'Ναι'} >Ναί</ToggleButton>
                                        <ToggleButton variant='outline-primary' value={'Όχι'} >Όχι</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Steamwand Δεξιά ΚΑΘΑΡΙΟΤΗΤΑ</Form.Label>
                                    <ToggleButtonGroup type='radio' name='steamWandRightClean'>
                                        <ToggleButton variant='outline-primary' value={'Ναι'} >Ναί</ToggleButton>
                                        <ToggleButton variant='outline-primary' value={'Όχι'} >Όχι</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Steamwand Αριστερα Σωστή Λειτουργία</Form.Label>
                                    <ToggleButtonGroup type='radio' name='steamWandRightOperational'>
                                        <ToggleButton variant='outline-primary' value={'Ναι'} >Ναί</ToggleButton>
                                        <ToggleButton variant='outline-primary' value={'Όχι'} >Όχι</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>


                                <div className='content-wrapper-header'>
                                    GROUP {groupIndex + 1}
                                </div>

                                {FORM_FIELDS_ESPRESSO_MACHINE_GROUP.map((field) => (
                                    <Form.Group as={Col} controlId={`${espressoMachineIndex}-${groupIndex}-${field.name}`} key={`${espressoMachineIndex}-${0}-${field.name}`}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData.espressoMachines[espressoMachineIndex]?.group?.[0]?.[field.name] || ''} onChange={(e) => handleGroupChange(e, espressoMachineIndex, groupIndex)} />
                                    </Form.Group>
                                ))}
                                <Form.Group as={Col}>
                                    <Form.Label>Gusket/Showers</Form.Label>
                                    <ToggleButtonGroup type='radio' name='steamWandLeftClean'>
                                        <ToggleButton variant='outline-primary' value={'Ναι'} >Ναί</ToggleButton>
                                        <ToggleButton variant='outline-primary' value={'Όχι'}  >Όχι</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>PORTAFILTER/FILTER BASKET</Form.Label>
                                    <ToggleButtonGroup type='radio' name='steamWandLeftClean'>
                                        <ToggleButton variant='outline-primary' value={'Ναι'} >Ναί</ToggleButton>
                                        <ToggleButton variant='outline-primary' value={'Όχι'} >Όχι</ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>

                                <Button onClick={() => setGroupIndex(prevIndex => prevIndex + 1)}>
                                    Επόμενο Group
                                </Button>

                                {groupIndex > 0 && FORM_FIELDS_ESPRESSO_MACHINE_GROUP.map((field) => (
                                    <Form.Group as={Col} controlId={`${espressoMachineIndex}-${groupIndex}-${field.name}`} key={`${espressoMachineIndex}-${groupIndex}-${field.name}`}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData.espressoMachines[espressoMachineIndex]?.group?.[groupIndex]?.[field.name]|| ''} onChange={(e) => handleGroupChange(e, espressoMachineIndex, groupIndex)} />
                                    </Form.Group>
                                ))}

                                <div className='content-wrapper-header'>
                                    Μύλοι Άλεσης
                                </div>

                                {FORM_FIELDS_GRINDER.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange}  />
                                    </Form.Group>
                                ))}

                                <div className='content-wrapper-header'>
                                    GRINDER CHECK
                                </div>

                                {FORM_FIELDS_GRINDER_CHECK.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} />
                                    </Form.Group>
                                ))}

                                <div className='content-wrapper-header'>
                                    GRINDER CORRECTION
                                </div>

                                {FORM_FIELDS_GRINDER_CORRECTION.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} />
                                    </Form.Group>
                                ))}

                                <div className='content-wrapper-header'>
                                    RECIPES
                                </div>

                                {FORM_FIELDS_RECIPES_GROUP.map((field) => (
                                    <Form.Group as={Col} controlId={field.name}>
                                        <Form.Label>{field.label}(*)</Form.Label>
                                        <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} />
                                    </Form.Group>
                                ))}



                                <div className='content-wrapper-header'>
                                    FILTRATION
                                </div>
                                <div className='content-data pt-4'>
                                    <Form.Group as={Col} controlId=''>
                                        <Form.Label>SYSTEM</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option value="BWT">BWT</option>
                                            <option value="REVERSE OSMOSIS">REVERSE OSMOSIS</option>
                                            <option value="CTU">CTU</option>
                                        </Form.Select>
                                    </Form.Group>

                                    {FORM_FIELDS_FILTRATION.map((field) => (
                                        <Form.Group as={Col} controlId={field.name}>
                                            <Form.Label>{field.label}(*)</Form.Label>
                                            <Form.Control type={field.type} name={field.name} value={formData[field.name] || ''} onChange={handleChange} />
                                        </Form.Group>
                                    ))}
                                </div>

                                <div className='content-data pt-4'>
                                    <button className='plus-button mt-4' type='submit'>
                                        <FontAwesomeIcon icon={faArrowRightLong} /> Αποθήκευση
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >
    )

    return content
}

export default ChecklistForm